/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import {
  RadioButtonCheckedRounded,
  RadioButtonUncheckedRounded,
  TuneRounded,
} from "@mui/icons-material";
import UTurnLeftRoundedIcon from "@mui/icons-material/UTurnLeftRounded";
import { notifyMinOptions } from "./utils";
import { getTimeZoneAbbreviation } from "../../utils/dateUtils";

const RepeatRadioBtn = ({ checked, label, mx, onClick }) => {
  return (
    <FormControlLabel
      sx={{ marginX: mx }}
      onClick={() => onClick()}
      control={
        <Checkbox
          m={0}
          checked={checked}
          icon={<RadioButtonUncheckedRounded />}
          checkedIcon={<RadioButtonCheckedRounded />}
        />
      }
      label={label}
      labelPlacement="bottom"
    />
  );
};

const HiberWakeupConfig = ({
  header,
  envId,
  enabled,
  toggleEnabled,
  schedule,
  updateSchedule,
  repeat,
  updateRepeat,
  notify,
  updateNotify,
}) => {
  const hourParsed = schedule?.split(":")[0] || "0";
  const minParsed = schedule?.split(":")[1] || "00";

  const updateScheduleHour = (hour) => {
    updateSchedule(`${hour}:${minParsed}`);
  };
  const updateScheduleMin = (min) => {
    updateSchedule(`${hourParsed}:${min}`);
  };

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  const determineDayGroup = () => {
    if (repeat.slice(0, 5).every(Boolean)) {
      if (repeat[5] && repeat[6]) {
        return "Everyday";
      } else if (!repeat[5] && !repeat[6]) {
        return "Weekdays";
      }
    }
    return "Custom";
  };

  const [dayGroupSelected, setDayGroupSelected] = useState("");
  const [showCustomDays, setShowCustomDays] = useState(false);
  const updateDayGroupSelected = () => setDayGroupSelected(determineDayGroup());

  useEffect(() => {
    updateDayGroupSelected();
  }, [repeat]);

  const changeRepeatView = (bool) => {
    setShowCustomDays(bool);
    if (!bool) {
      updateDayGroupSelected();
    }
  };

  const selectEveryday = () => {
    if (dayGroupSelected !== "Everyday") {
      updateRepeat(Array(7).fill(true));
    }
  };
  const selectWeekdays = () => {
    if (dayGroupSelected !== "Weekdays") {
      const weekdays = Array(7).fill(true);
      weekdays[5] = false;
      weekdays[6] = false;
      updateRepeat(weekdays);
    }
  };
  const selectDay = (idx) => {
    const prevDays = [...repeat];
    prevDays[idx] = !prevDays[idx];
    updateRepeat(prevDays);
  };

  const toggleNotifyMin = (idx) => {
    const currNotify = [...notify];
    currNotify[idx] = !currNotify[idx];
    updateNotify(currNotify);
  };

  return (
    <Paper elevation={5}>
      <Stack pt={"5px"} pb={"10px"} spacing={1}>
        <Stack
          direction="row"
          alignItems={"center"}
          px={"10px"}
          justifyContent={"space-between"}
        >
          <Typography variant="h6">{header}</Typography>
          <Switch
            color="secondary"
            checked={enabled}
            onChange={(e) => toggleEnabled(envId, e)}
          />
        </Stack>
        <Stack direction="row" alignItems={"center"} px={"10px"} spacing={1}>
          <Typography variant="h6">Scheduled at</Typography>
          <Select
            size="small"
            value={hourParsed}
            onChange={(e) => updateScheduleHour(e.target.value)}
          >
            {Array(24)
              .fill(0)
              .map((_, hour) =>
                hour < 10 ? "0" + hour.toString() : hour.toString()
              )
              .map((hour) => (
                <MenuItem key={hour} value={hour}>
                  {hour}
                </MenuItem>
              ))}
          </Select>
          <Typography variant="h6">:</Typography>
          <Select
            size="small"
            value={minParsed}
            onChange={(e) => updateScheduleMin(e.target.value)}
          >
            {["00", "15", "30", "45"].map((minute) => (
              <MenuItem key={minute} value={minute}>
                {minute}
              </MenuItem>
            ))}
          </Select>
          <Typography variant="h6">{getTimeZoneAbbreviation()}</Typography>
        </Stack>
        <Stack direction="row" alignItems={"center"} px={"10px"}>
          <Typography variant="h6">Repeats on </Typography>
          {showCustomDays ? (
            days.map((day, idx) => {
              return (
                <RepeatRadioBtn
                  key={idx}
                  checked={repeat[idx]}
                  label={day}
                  mx={0}
                  onClick={() => selectDay(idx)}
                />
              );
            })
          ) : (
            <>
              <RepeatRadioBtn
                checked={dayGroupSelected === "Everyday"}
                label={"Everyday"}
                mx={2}
                onClick={() => selectEveryday()}
              />
              <RepeatRadioBtn
                checked={dayGroupSelected === "Weekdays"}
                label={"Weekdays"}
                mx={2}
                onClick={() => selectWeekdays()}
              />
              <RepeatRadioBtn
                checked={dayGroupSelected === "Custom"}
                label={"Custom"}
                mx={2}
                onClick={() => changeRepeatView(true)}
              />
            </>
          )}

          <IconButton
            size="small"
            onClick={() => changeRepeatView(!showCustomDays)}
          >
            {showCustomDays ? (
              <UTurnLeftRoundedIcon sx={{ transform: "rotate(90deg)" }} />
            ) : (
              <TuneRounded />
            )}
          </IconButton>
        </Stack>
        <Stack direction="row" alignItems={"center"} px={"10px"}>
          <Typography variant="h6">Notify before (mins)</Typography>
          {notifyMinOptions.map((minutes, idx) => {
            return (
              <RepeatRadioBtn
                key={minutes}
                checked={notify[idx]}
                label={minutes}
                mx={0}
                onClick={() => toggleNotifyMin(idx)}
              />
            );
          })}
        </Stack>
      </Stack>
    </Paper>
  );
};

const ScheduledConfigs = ({ env, envOrig, setUpdates, onSetUpdates }) => {
  const envId = env.id;

  const updateAutoDeleteHours = (event) => {
    setUpdates((prev) => {
      if (!prev[envId]) {
        prev[envId] = {};
      }
      prev[envId].autoDeleteHours = event.target.value;
      if (envOrig.autoDeleteHours.toString() === event.target.value) {
        delete prev[envId].autoDeleteHours;
      }
      return prev;
    });
    onSetUpdates();
  };

  const updateAutoDeleteEnabled = () => {
    setUpdates((prev) => {
      if (!prev[envId]?.autoDeleteEnabled) {
        prev[envId] = {
          ...(prev[envId] && prev[envId]),
          autoDeleteEnabled: env?.autoDeleteEnabled,
        };
      }
      prev[envId].autoDeleteEnabled = !prev[envId]?.autoDeleteEnabled;
      if (envOrig.autoDeleteEnabled === prev[envId].autoDeleteEnabled) {
        delete prev[envId].autoDeleteEnabled;
      }
      return prev;
    });
    onSetUpdates();
  };

  const updateAutoHibernateEnabled = () => {
    setUpdates((prev) => {
      if (!prev[envId]?.autoHibernateEnabled) {
        prev[envId] = {
          ...(prev[envId] && prev[envId]),
          autoHibernateEnabled: env?.autoHibernateEnabled,
        };
      }
      prev[envId].autoHibernateEnabled = !prev[envId]?.autoHibernateEnabled;
      if (envOrig.autoHibernateEnabled === prev[envId].autoHibernateEnabled) {
        delete prev[envId].autoHibernateEnabled;
      }
      return prev;
    });
    onSetUpdates();
  };

  const updateAutoHibernateSchedule = (time) => {
    setUpdates((prev) => {
      if (!prev[envId]) {
        prev[envId] = {};
      }
      prev[envId].autoHibernateSchedule = time;
      if (envOrig.autoHibernateSchedule === time) {
        delete prev[envId].autoHibernateSchedule;
      }
      return prev;
    });
    onSetUpdates();
  };

  const updateAutoHibernateRepeat = (repeat) => {
    setUpdates((prev) => {
      if (!prev[envId]) {
        prev[envId] = {};
      }
      prev[envId].autoHibernateRepeat = repeat;
      if (envOrig.autoHibernateRepeat.toString() === repeat.toString()) {
        delete prev[envId].autoHibernateRepeat;
      }
      return prev;
    });
    onSetUpdates();
  };

  const updateAutoHibernateNotify = (notify) => {
    setUpdates((prev) => {
      if (!prev[envId]) {
        prev[envId] = {};
      }
      prev[envId].autoHibernateNotify = notify;
      if (envOrig.autoHibernateNotify.toString() === notify.toString()) {
        delete prev[envId].autoHibernateNotify;
      }
      return prev;
    });
    onSetUpdates();
  };

  const updateAutoWakeupEnabled = () => {
    setUpdates((prev) => {
      if (!prev[envId]?.autoWakeupEnabled) {
        prev[envId] = {
          ...(prev[envId] && prev[envId]),
          autoWakeupEnabled: env?.autoWakeupEnabled,
        };
      }
      prev[envId].autoWakeupEnabled = !prev[envId]?.autoWakeupEnabled;
      if (envOrig.autoWakeupEnabled === prev[envId].autoWakeupEnabled) {
        delete prev[envId].autoWakeupEnabled;
      }
      return prev;
    });
    onSetUpdates();
  };

  const updateAutoWakeupSchedule = (time) => {
    setUpdates((prev) => {
      if (!prev[envId]) {
        prev[envId] = {};
      }
      prev[envId].autoWakeupSchedule = time;
      if (envOrig.autoWakeupSchedule === time) {
        delete prev[envId].autoWakeupSchedule;
      }
      return prev;
    });
    onSetUpdates();
  };

  const updateAutoWakeupRepeat = (repeat) => {
    setUpdates((prev) => {
      if (!prev[envId]) {
        prev[envId] = {};
      }
      prev[envId].autoWakeupRepeat = repeat;
      if (envOrig.autoWakeupRepeat.toString() === repeat.toString()) {
        delete prev[envId].autoWakeupRepeat;
      }
      return prev;
    });
    onSetUpdates();
  };

  const updateAutoWakeupNotify = (notify) => {
    setUpdates((prev) => {
      if (!prev[envId]) {
        prev[envId] = {};
      }
      prev[envId].autoWakeupNotify = notify;
      if (envOrig.autoWakeupNotify.toString() === notify.toString()) {
        delete prev[envId].autoWakeupNotify;
      }
      return prev;
    });
    onSetUpdates();
  };

  return (
    <Box sx={{ width: "100%", height: "100%", padding: "10px" }}>
      <Stack sx={{ height: 1 }}>
        <Typography variant="h5">Scheduled Configurations</Typography>
        <Divider />
        <Grid
          container
          columns={17}
          spacing={0.5}
          rowGap={1}
          // columnGap={{ md: 1, xl: "auto" }}
          sx={{ pt: 2, height: "90%", boxSizing: "border-box" }}
          justifyContent={"space-between"}
        >
          <Grid xs={17} xl={8}>
            <Paper
              elevation={env.autoDeleteEnabled ? 6 : 4}
              sx={{ width: "100%" }}
            >
              <Stack
                direction="row"
                alignItems={"center"}
                px={"10px"}
                justifyContent={"space-between"}
              >
                <Typography variant="h6">
                  Auto Delete DBs after {env.autoDeleteHours} hours
                </Typography>
                {/* <Typography variant="h6">Auto Delete DBs after </Typography>
              <TextField
                type="number"
                variant="standard"
                onChange={(e) => updateAutoDeleteHours(e)}
                sx={{ width: "20%", textAlign: "right" }}
                InputProps={{
                  disableUnderline: true,
                  inputProps: {
                    min: 0,
                    max: 12,
                  },
                }}
                value={env.autoDeleteHours}
              />
              <Typography variant="h6">hours</Typography> */}
                <Switch
                  disabled
                  color="secondary"
                  checked={env.autoDeleteEnabled}
                  // onChange={(e) => updateAutoDeleteEnabled()}
                />
              </Stack>
            </Paper>
          </Grid>
          <Grid xs={17} xl={8}></Grid>
          <Grid xs={17} xl={8}>
            <HiberWakeupConfig
              header={"Auto Hibernate"}
              envId={env.id}
              enabled={env.autoHibernateEnabled}
              toggleEnabled={updateAutoHibernateEnabled}
              schedule={env.autoHibernateSchedule}
              updateSchedule={updateAutoHibernateSchedule}
              repeat={env.autoHibernateRepeat}
              updateRepeat={updateAutoHibernateRepeat}
              notify={env.autoHibernateNotify}
              updateNotify={updateAutoHibernateNotify}
            />
          </Grid>
          <Grid xs={17} xl={8}>
            <HiberWakeupConfig
              header={"Auto Wake Up"}
              envId={env.id}
              enabled={env.autoWakeupEnabled}
              toggleEnabled={updateAutoWakeupEnabled}
              schedule={env.autoWakeupSchedule}
              updateSchedule={updateAutoWakeupSchedule}
              repeat={env.autoWakeupRepeat}
              updateRepeat={updateAutoWakeupRepeat}
              notify={env.autoWakeupNotify}
              updateNotify={updateAutoWakeupNotify}
            />
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default ScheduledConfigs;
