import { useLazyFetch, useLazyPost } from "../utils/apiclient";
import { devopsUrl } from "../utils/urls";
import { getHeaders } from "../utils/apiclient";

export const useGetCPRequestsToUser = () => {
  return useLazyFetch(`${devopsUrl}/requests/control-plane`, {
    headers: getHeaders(),
  });
};

export const usePatchCPRequest = () => {
  return useLazyPost(`${devopsUrl}/requests/control-plane`, {
    headers: getHeaders(),
    method: "PATCH",
  });
};

export const useGetByoaSubscriptionRequestsToUser = () => {
  return useLazyFetch(`${devopsUrl}/requests/byoa-subscriptions`, {
    headers: getHeaders(),
  });
};

export const usePatchByoaSubscriptionRequest = () => {
  return useLazyPost(`${devopsUrl}/requests/byoa-subscriptions`, {
    headers: getHeaders(),
    method: "PATCH",
  });
};
