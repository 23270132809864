import { useLazyFetch, useLazyPost } from "../utils/apiclient";
import { qaosUrl } from "../utils/urls";
import { getHeaders } from "../utils/apiclient";

export const useGetAutomationRuns = (deployemntStage, tenantUrl) => {
  return useLazyFetch(
    `${qaosUrl}/tessell/${deployemntStage}/tenants/${tenantUrl}/automation-runs`,
    {
      headers: { ...getHeaders(), Action: "LIST_ALL" },
    }
  );
};

export const useTriggerAutomationRun = (deployemntStage, tenantUrl) => {
  return useLazyPost(
    `${qaosUrl}/tessell/${deployemntStage}/tenants/${tenantUrl}/automation-runs`,
    {
      headers: { ...getHeaders(), Action: "TRIGGER" },
      method: "POST",
    }
  );
};
