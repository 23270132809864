import { useContext, useEffect, useState } from "react";
import { Box, Divider, IconButton, Typography, useTheme } from "@mui/material";
import { UserContext } from "../../../contexts";
import { tokens } from "../../../theme";
import { Edit } from "@mui/icons-material";
import DataGridButton from "../../../components/DataGridButton";
import { red, green, orange, brown } from "@mui/material/colors";
import { simpleCardStyle } from "../../../styles/cardStyles";
import styles from "../styles";
import { dataGridButtonTypography } from "../../../styles/DataGridStyles";
import { stackCenterGap } from "../../../styles/utilStyles";
import { dataGridButtonStyles } from "../../../styles/buttonStyles";
import {
  DISABLED_PATCH_RELEASE_STATUS,
  USER_ROLES,
} from "../../../utils/constants";

const getProperties = () => {
  return {
    UNDER_VALIDATION: {
      color: orange[800],
    },
    ARCHIVED: {
      color: brown[700],
    },
    IN_PRODUCTION: {
      color: green[700],
    },
    REJECTED: {
      color: red[800],
    },
    DELETED: {
      color: brown[700],
    },
  };
};

const ReleasesCard = ({ label, handlePatchLabel, handleUpdateStatus }) => {
  const loggedInUser = useContext(UserContext);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [isUserReleaseOwner, setIsUserReleaseOwner] = useState(false);
  useEffect(() => {
    setIsUserReleaseOwner(
      loggedInUser?.data?.roles?.includes(USER_ROLES.RELEASE_OWNER)
    );
  }, [loggedInUser?.data]);

  return (
    <Box sx={simpleCardStyle(colors)}>
      <Box sx={styles.releaseCardHeadingStack}>
        <Typography variant="h3">
          Release &nbsp;{label?.patches?.[0]?.patch_label?.slice(4)}
        </Typography>
        <Box sx={stackCenterGap} />
        <DataGridButton
          sx={dataGridButtonStyles.patchButton}
          disabled={DISABLED_PATCH_RELEASE_STATUS.includes(label?.status)}
          onClick={(event) => {
            event.stopPropagation();
            if (label?.release_label) {
              handlePatchLabel(label.release_label);
            }
          }}
        >
          <Typography sx={dataGridButtonTypography(colors)}>
            {!isUserReleaseOwner && "PREVIEW"} PATCH
          </Typography>
        </DataGridButton>
      </Box>
      <Divider />
      <Box sx={styles.cardSummaryItemStyle}>
        <Typography variant="h4" sx={styles.stageNameStyle}>
          Status
        </Typography>
        <Divider orientation="vertical" flexItem />
        <Typography
          variant="h5"
          sx={styles.stageStatusStyle(colors, getProperties, label?.status)}
        >
          {label?.status || "UNASSIGNED"}
        </Typography>
        {isUserReleaseOwner && (
          <IconButton
            onClick={() => {
              if (label?.release_label) {
                handleUpdateStatus(
                  label.release_label,
                  label?.status,
                  Object.keys(getProperties())
                );
              }
            }}
          >
            <Edit fontSize="small" />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default ReleasesCard;
