import { VULNERABILITY_STATUS } from "../../utils/constants";

export const updateSelectedItemsInFilter = (setState, key) => {
  return (newValue) => {
    setState((prev) => ({
      ...prev,
      [key]: newValue,
    }));
  };
};
export const createQueryString = (selectedItemsInFilter) => {
  return (
    "?" +
    Object.keys(selectedItemsInFilter)
      .map((field) =>
        selectedItemsInFilter[field].map((item) => `${field}=${item}`)
      )
      .flat()
      .join("&")
  );
};

export const handleRequestApproval = (
  row,
  setConfirmationDialog,
  patchVulnerability
) => {
  setConfirmationDialog({
    isOpen: true,
    title: "Request Approval",
    content: `You are going to request approval for the "${row?.vulnerabilityId}" in "${row?.packageName}" in "${row?.repository}"`,
    handleDialogConfirm: () => {
      patchVulnerability({ action: "request-approval" }, `/${row?.id}`);
    },
  });
};

export const handleAdminister = (
  row,
  action,
  setConfirmationDialog,
  patchVulnerability
) => {
  setConfirmationDialog({
    isOpen: true,
    title: `${action} Vulnerability`,
    content: `You are going to ${action} the "${row?.vulnerabilityId}" in "${row?.packageName}" in "${row?.repository}"`,
    handleDialogConfirm: () => {
      patchVulnerability(
        {
          action: "administer",
          data: {
            status:
              action === "Approve"
                ? VULNERABILITY_STATUS.APPROVED
                : VULNERABILITY_STATUS.REJECTED,
          },
        },
        `/${row?.id}`
      );
    },
  });
};

export const handleExcludeForAll = (
  row,
  setConfirmationDialog,
  patchVulnerability
) => {
  setConfirmationDialog({
    isOpen: true,
    title: `Exclude Vulnerability for all`,
    content: `You are going to ${row?.excludedForAll ? "un-" : ""}exclude "${
      row?.vulnerabilityId
    }" in "${row?.packageName}" package for all repositories`,
    handleDialogConfirm: () => {
      patchVulnerability(
        {
          action: "administer",
          data: {
            excludedForAll: !row?.excludedForAll,
          },
        },
        `/${row?.id}`
      );
    },
  });
};
