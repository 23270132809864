import { useEffect, useState } from "react";
import SimpleRefresh from "./SimpleRefresh";
import CustomRefresh from "./CustomRefresh";
import UiRefresh from "./UiRefresh";

const RefreshDialog = ({ refreshDialog, setRefreshDialog, refreshEnv }) => {
  const [selectedRefreshBase, setSelectedRefreshBase] = useState("latest-main");
  const [refreshType, setRefreshType] = useState(false);

  useEffect(() => {
    if (refreshDialog.isOpen) {
      setRefreshType("simple");
    }
  }, [refreshDialog]);

  const resetDialog = () => {
    setRefreshDialog({ selectedEnv: {}, isOpen: false });
    setSelectedRefreshBase("latest-main");
    setRefreshType("");
  };

  const getDialog = () => {
    switch (refreshType) {
      case "simple":
        return (
          <SimpleRefresh
            env={refreshDialog?.selectedEnv}
            selectedRefreshBase={selectedRefreshBase}
            setSelectedRefreshBase={setSelectedRefreshBase}
            setRefreshType={setRefreshType}
            resetDialog={resetDialog}
            refreshEnv={refreshEnv}
          />
        );
      case "custom":
        return (
          <CustomRefresh
            env={refreshDialog?.selectedEnv}
            selectedRefreshBase={selectedRefreshBase}
            setRefreshType={setRefreshType}
            resetDialog={resetDialog}
            refreshEnv={refreshEnv}
          />
        );
      case "ui":
        return (
          <UiRefresh
            env={refreshDialog?.selectedEnv}
            setRefreshType={setRefreshType}
            resetDialog={resetDialog}
            refreshEnv={refreshEnv}
          />
        );
      default:
        return <></>;
    }
  };

  return <>{getDialog()}</>;
};

export default RefreshDialog;
