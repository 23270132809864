import {
  IMAGE_REPO_PREFIX,
  IMAGE_REPO_SUFFIX,
  RMO_ARTIFACTS_ENUM,
} from "../utils/constants";

export const adaptReleaseManifest = (releaseManifest) => {
  const curr = structuredClone(releaseManifest);
  return Object.keys(curr).reduce((tot, artifactType) => {
    if (
      artifactType !== RMO_ARTIFACTS_ENUM && // TODO change this condition when bringing artifacts back
      typeof curr[artifactType] === "object" &&
      curr[artifactType] !== null
    ) {
      return {
        ...tot,
        [artifactType]: Object.keys(curr[artifactType]).reduce(
          (total, service) => {
            const obj1 = { ...curr[artifactType][service], valueFrom: "tag" };
            if (artifactType !== RMO_ARTIFACTS_ENUM) {
              const obj2 = {
                ...obj1,
                repository: `${IMAGE_REPO_PREFIX[artifactType]}${IMAGE_REPO_SUFFIX[0]}`,
              };
              return { ...total, [service]: obj2 };
            } else {
              return { ...total, [service]: obj1 };
            }
          },
          {}
        ),
      };
    } else {
      return tot;
    }
  }, {});
};
