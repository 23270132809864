import { useLazyFetch, useLazyPost } from "../utils/apiclient";
import { devopsUrl } from "../utils/urls";
import { getHeaders } from "../utils/apiclient";

export const vulnerabilitiesUrl = `${devopsUrl}/code/vulnerabilities`;

export const useGetVulnerabilities = (vulnerabilityType) => {
  return useLazyFetch(`${vulnerabilitiesUrl}/${vulnerabilityType}`, {
    headers: getHeaders(),
  });
};

export const useGetAllVulnerabilityFilterOptions = (vulnerabilityType) => {
  return useLazyFetch(`${vulnerabilitiesUrl}/${vulnerabilityType}/fields`, {
    headers: getHeaders(),
  });
};

export const usePatchVulnerability = () => {
  return useLazyPost(`${vulnerabilitiesUrl}`, {
    headers: getHeaders(),
    method: "PATCH",
  });
};
