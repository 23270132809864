import { useLazyFetch, useLazyPost } from "../utils/apiclient";
import { authUrl } from "../utils/urls";

export const useGetLoginUrl = () => {
  return useLazyFetch(`${authUrl}/login/url`, {});
};

export const useLoginCallback = () => {
  return useLazyPost(`${authUrl}/login/callback`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const useIsTokenValid = () => {
  return useLazyPost(`${authUrl}/token/validate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const extendToken = async () => {
  return fetch(`${authUrl}/token/extend`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};
