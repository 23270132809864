/* eslint-disable react-hooks/exhaustive-deps */
import { tokens } from "../../theme";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import DataGridButton from "../../components/DataGridButton";
import { REQUEST_STATUS, REQUEST_VIEWS } from "../../utils/constants";
import { simpleCardStyle } from "../../styles/cardStyles";
import styles from "./styles";
import { dataGridButtonTypography } from "../../styles/DataGridStyles";
import { dataGridButtonStyles } from "../../styles/buttonStyles";

const RequestCard = ({
  view,
  request,
  handleRequestApproval,
  requestCardKeys,
  approveDisabled,
  approveToolTipText,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={simpleCardStyle(colors)}>
      <Box sx={styles.verticalStack}>
        {requestCardKeys(view).map((item, i) => {
          return (
            <Grid container key={i}>
              <Grid item xs={3} sx={styles.requestJsonKey}>
                {item.name}:
              </Grid>
              <Grid item xs={9}>
                {request?.[item.key]?.toString()}
              </Grid>
            </Grid>
          );
        })}

        <Box sx={styles.reverseStack}>
          {request?.status === REQUEST_STATUS.PENDING &&
          view === REQUEST_VIEWS.requestsReceived ? (
            <>
              <DataGridButton
                sx={dataGridButtonStyles.requestApproveButton}
                disabled={approveDisabled}
                onClick={() => {
                  handleRequestApproval(REQUEST_STATUS.APPROVED, request?.id);
                }}
                toolTipProps={{
                  title: (
                    <Typography variant="body2">
                      {approveToolTipText || ""}
                    </Typography>
                  ),
                }}
              >
                <Typography sx={dataGridButtonTypography(colors)}>
                  Approve
                </Typography>
              </DataGridButton>
              <DataGridButton
                sx={dataGridButtonStyles.requestApproveButton}
                hue={colors.red}
                onClick={() => {
                  handleRequestApproval(REQUEST_STATUS.REJECTED, request?.id);
                }}
              >
                <Typography sx={dataGridButtonTypography(colors)}>
                  Reject
                </Typography>
              </DataGridButton>
            </>
          ) : (
            <Typography
              variant="h5"
              sx={styles.requestStatusTypo(colors, request?.status)}
            >
              {request?.status}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default RequestCard;
