import { useContext, useState } from "react";
import { UserContext } from "../../contexts";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Switch, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { USER_ROLES } from "../../utils/constants";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import {
  Assessment,
  CallMerge,
  DashboardRounded,
  DescriptionOutlined,
  Groups,
  LogoDev,
  MenuOutlined,
  MotionPhotosAuto,
  PendingActionsRounded,
  PestControlRounded,
  Speed,
  TouchAppRounded,
} from "@mui/icons-material";
import { Argo } from "../../icons";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const loggedInUser = useContext(UserContext);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Environments");
  const isNotLocalBackend =
    sessionStorage.getItem("isNotLocalBackend") === "true";
  const items = [
    {
      type: "group",
      title: "Manage",
    },
    (loggedInUser?.data?.roles?.includes(USER_ROLES.DEVOPS_TEAM) ||
      loggedInUser?.data?.roles?.includes(USER_ROLES.INFRA_TEAM)) && {
      type: "item",
      title: "Argo",
      to: "/argo",
      icon: <Argo />,
    },
    {
      type: "item",
      title: "Environments",
      to: "/",
      icon: <LogoDev />,
    },
    {
      type: "item",
      title: "Releases",
      to: "/releases",
      icon: <TouchAppRounded />,
    },
    {
      type: "item",
      title: "Requests",
      to: "/requests",
      icon: <PendingActionsRounded />,
    },
    {
      type: "item",
      title: "Vulnerabilities",
      to: "/vulnerabilities",
      icon: <PestControlRounded />,
    },
    {
      type: "group",
      title: "View Only",
    },
    {
      type: "item",
      title: "Code Coverage",
      to: "/code-coverage",
      icon: <Speed />,
    },
    loggedInUser?.data?.roles?.some((role) =>
      [
        USER_ROLES.DEVOPS_TEAM,
        USER_ROLES.INFRA_TEAM,
        USER_ROLES.QA_AUTOMATION_TEAM,
      ].includes(role)
    ) && {
      type: "item",
      title: "Logs",
      to: "/logs",
      icon: <DescriptionOutlined />,
    },
    {
      type: "item",
      title: "Pull Requests",
      to: "/pull-requests",
      icon: <CallMerge />,
    },
    {
      type: "item",
      title: "QA",
      to: "/qa",
      icon: <Assessment />,
    },
    false &&
      ({
        type: "item",
        title: "Projects",
        to: "/projects",
        icon: <DashboardRounded />,
      },
      {
        type: "item",
        title: "Teams",
        to: "/teams",
        icon: <Groups />,
      }),
  ].filter(Boolean);

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[700]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: colors.blue[300] + " !important",
        },
        "& .pro-menu-item.active": {
          color: colors.blue[500] + " !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlined /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  Tessell Convoy
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlined />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            {items.map((item, index) =>
              item.type === "item" ? (
                <Item
                  key={index}
                  title={item.title}
                  to={item.to}
                  icon={item.icon}
                  selected={selected}
                  setSelected={setSelected}
                />
              ) : (
                <Typography
                  key={index}
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  {item.title}
                </Typography>
              )
            )}
            {(() => {
              if (window.location.origin.startsWith("http://localhost")) {
                return (
                  <>
                    <Typography variant="h6" component={"span"}>
                      Convoy-Dev backend
                    </Typography>
                    <Switch
                      color="secondary"
                      checked={isNotLocalBackend === true}
                      onChange={() => {
                        sessionStorage.setItem(
                          "isNotLocalBackend",
                          !isNotLocalBackend
                        );
                        window.location.reload(true);
                      }}
                    />
                  </>
                );
              }
            })()}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
