import { commonDataGridStyles } from "../../../../styles/DataGridStyles";

export const styles = {
  modalBox: (colors) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1000,
    p: 4,
    ...commonDataGridStyles(colors),
    height: "50vh",
  }),
};
