import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const styles = {
  box: {
    mb: "4vh",
  },
  title: (colors) => ({
    color: colors.grey[100],
    fontWeight: "bold",
    m: "0 0 5px 0",
  }),
  subtitle: (colors) => ({
    color: colors.green[400],
  }),
};

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box sx={styles.box}>
      <Typography variant="h2" sx={styles.title(colors)}>
        {title}
      </Typography>
      <Typography variant="h5" sx={styles.subtitle(colors)}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
