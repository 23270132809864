import ArgoIcon from "./ArgoIcon";
import MsSqlServerIcon from "./MsSqlServerIcon";
import OracleIcon from "./OracleIcon";
import PostgreSqlIcon from "./PostgreSqlIcon";
import MySqlIcon from "./MySqlIcon";

export const Argo = ArgoIcon;
export const MsSqlServer = MsSqlServerIcon;
export const Oracle = OracleIcon;
export const PostgreSql = PostgreSqlIcon;
export const MySql = MySqlIcon;
