import moment from "moment";
import { useState, useEffect } from "react";
import { tokens } from "../../../../theme";
import { Box, Modal, Typography, useTheme } from "@mui/material";
import Notification from "../../../../components/Notification";
import ConfirmationDialog from "../../../../components/ConfirmationDialog";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import {
  useGetByoaSubscriptions,
  useDeboardByoaSubscriptions,
} from "../../../../api/envs";
import { timeFormat } from "../../../../utils/constants";
import { styles } from "./styles";

const getByoaSubscriptionsRequestQueryParams = (byoaModal) => {
  return byoaModal?.selectedEnv?.url
    ? `?tenantUrl=${byoaModal?.selectedEnv?.url}`
    : "";
};
const BYOASubscriptionsModal = ({ byoaModal, setByoaModal }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [subscriptions, setSubscriptions] = useState([]);

  const [notify, setNotify] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    title: "",
    content: "",
    handleDialogConfirm: () => {},
  });

  const resetDialog = () => {
    setConfirmationDialog({
      isOpen: false,
      title: "",
      content: "",
      handleDialogConfirm: () => {},
    });
  };

  const {
    postData: getByoaSubscriptions,
    response: getByoaSubscriptionsResp,
    isLoading: isLoadingByoaSubscriptions,
  } = useGetByoaSubscriptions();

  const {
    postData: deboardByoaSubscriptions,
    response: deboardByoaSubscriptionsResp,
    responseStatus: deboardByoaSubscriptionsRespStatus,
  } = useDeboardByoaSubscriptions();

  useEffect(() => {
    getByoaSubscriptions(getByoaSubscriptionsRequestQueryParams(byoaModal));
  }, [byoaModal]);

  useEffect(() => {
    if (Array.isArray(getByoaSubscriptionsResp?.response)) {
      setSubscriptions(getByoaSubscriptionsResp.response);
    }
  }, [getByoaSubscriptionsResp]);

  useEffect(() => {
    const message =
      deboardByoaSubscriptionsResp?.message ||
      deboardByoaSubscriptionsResp?.detail?.message ||
      deboardByoaSubscriptionsResp?.detail ||
      "error occured";
    if (
      message?.length &&
      typeof deboardByoaSubscriptionsRespStatus === "number"
    ) {
      setMessage(message);
      setSeverity(
        deboardByoaSubscriptionsRespStatus === 200 ? "success" : "error"
      );
      setNotify(true);
      getByoaSubscriptions(getByoaSubscriptionsRequestQueryParams(byoaModal));
    }
  }, [deboardByoaSubscriptionsResp, deboardByoaSubscriptionsRespStatus]);

  const columns = [
    {
      field: "subscriptionName",
      renderHeader: () => <Typography variant="h5">Name</Typography>,
      flex: 1.5,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { subscriptionName } }) => (
        <Typography variant="h5">{subscriptionName || ""}</Typography>
      ),
    },
    byoaModal?.selectedEnv?.url || {
      field: "tenantUrl",
      renderHeader: () => <Typography variant="h5">Tenant</Typography>,
      flex: 1.5,
      renderCell: ({ row: { tenantUrl } }) => (
        <Typography variant="h5">{tenantUrl?.split(".")?.[0] || ""}</Typography>
      ),
    },
    {
      field: "cloudEntityMetadata",
      renderHeader: () => <Typography variant="h5">Cloud</Typography>,
      flex: 1,
      renderCell: ({ row: { cloudEntityMetadata } }) => (
        <Typography variant="h5">{cloudEntityMetadata?.cloud || ""}</Typography>
      ),
    },
    {
      field: "status",
      renderHeader: () => <Typography variant="h5">Status</Typography>,
      flex: 2,
      renderCell: ({ row: { status } }) => (
        <Typography variant="h5">{status || ""}</Typography>
      ),
    },
    {
      field: "cloudEntityId",
      renderHeader: () => <Typography variant="h5">Cloud Entity Id</Typography>,
      flex: 1.5,
      renderCell: ({ row: { cloudEntityId } }) => (
        <Typography variant="h5">{cloudEntityId || ""}</Typography>
      ),
    },
    {
      field: "dateCreated",
      renderHeader: () => <Typography variant="h5">Date Created</Typography>,
      flex: 2,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { dateCreated } }) => (
        <Typography variant="h5">
          {moment(dateCreated)?.format(timeFormat) || ""}
        </Typography>
      ),
    },
    byoaModal?.selectedEnv?.url && {
      field: "actions",
      type: "actions",
      flex: 0.5,
      getActions: ({ row }) =>
        [
          <GridActionsCellItem
            label="Delete"
            onClick={() =>
              setConfirmationDialog({
                isOpen: true,
                title: "Delete Subscription",
                content: `Delete actual subscription from tenant before this. Delete ${row.subscriptionName} subscription from convoy?`,
                handleDialogConfirm: () => {
                  deboardByoaSubscriptions(row.id);
                },
              })
            }
            showInMenu
          />,
        ].filter(Boolean),
    },
  ].filter(Boolean);

  return (
    <>
      <Notification
        notify={notify}
        severity={severity}
        setNotify={setNotify}
        message={message}
      />
      <ConfirmationDialog
        isOpen={confirmationDialog.isOpen}
        title={confirmationDialog.title}
        handleDialogConfirm={confirmationDialog.handleDialogConfirm}
        content={confirmationDialog.content}
        resetDialog={resetDialog}
      />
      <Modal
        open={byoaModal?.isOpen}
        onClose={() => setByoaModal({ isOpen: false })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styles.modalBox(colors)}>
          <DataGridPro
            disableSelectionOnClick
            getRowId={(row) => row?.id}
            loading={isLoadingByoaSubscriptions}
            rows={subscriptions}
            columns={columns}
          />
        </Box>
      </Modal>
    </>
  );
};
export default BYOASubscriptionsModal;
