/* eslint-disable react-hooks/exhaustive-deps */
import { cloneElement, Fragment, useContext, useEffect, useState } from "react";
import { tokens } from "../../theme";
import { UserContext } from "../../contexts";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  Stack,
  Tab,
  Typography,
  useTheme,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CPRequests from "./CPRequests";
import ByoaSubscriptions from "./ByoaSubscriptions";
import Header from "../../components/Header";
import DataGridButton from "../../components/DataGridButton";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { toPascalCase, toTitleCase } from "../../utils/helperFunctions";
import { REQUEST_STATUS, REQUEST_VIEWS } from "../../utils/constants";
import styles from "./styles";
import { dataGridButtonTypography } from "../../styles/DataGridStyles";
import { dataGridButtonStyles } from "../../styles/buttonStyles";

const Requests = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const loggedInUser = useContext(UserContext);

  const [selectedTab, setSelectedTab] = useState("Control Plane");
  const [selectedView, setSelectedView] = useState(
    loggedInUser?.data?.quotas?.controlPlane?.allocated
      ? REQUEST_VIEWS.requestsReceived
      : REQUEST_VIEWS.requestsMade
  );
  const [refreshToggle, setRefreshToggle] = useState(false);
  const [requestFilter, setRequestFilter] = useState({ PENDING: true });

  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    content: "",
    handleDialogConfirm: () => {},
  });

  const resetDialog = () => {
    setConfirmationDialog({
      isOpen: false,
      content: "",
      handleDialogConfirm: () => {},
    });
  };

  const tabContentComponentProps = {
    view: selectedView,
    setConfirmationDialog: setConfirmationDialog,
    requestFilter: requestFilter,
    refreshToggle: refreshToggle,
    setRefreshToggle: setRefreshToggle,
  };
  const tabs = [
    {
      value: "Control Plane",
      component: <CPRequests {...tabContentComponentProps} />,
    },
    {
      value: "BYOA Subscriptions",
      component: <ByoaSubscriptions {...tabContentComponentProps} />,
    },
  ];

  return (
    <Box sx={styles.mainBox}>
      <ConfirmationDialog
        isOpen={confirmationDialog.isOpen}
        handleDialogConfirm={confirmationDialog.handleDialogConfirm}
        content={confirmationDialog.content}
        resetDialog={resetDialog}
      />
      <Grid sx={styles.gridContainer} container columns={2}>
        <Grid item xs={1}>
          <Header
            title="Requests"
            subtitle="Manage the requests pending for approval"
          />
        </Grid>
        <Grid item xs={1}>
          <Stack direction="row-reverse">
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              onClick={() => {
                setRefreshToggle((prev) => !prev);
              }}
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                RELOAD
              </Typography>
            </DataGridButton>
            {Object.keys(REQUEST_STATUS).map((item, i) => (
              <Fragment key={i}>
                <Box sx={styles.filterText}>{toPascalCase(item)}</Box>
                <Switch
                  color="secondary"
                  checked={requestFilter?.[item]}
                  onChange={() =>
                    setRequestFilter((prev) => ({
                      ...prev,
                      [item]: !(prev?.[item] || false),
                    }))
                  }
                ></Switch>
              </Fragment>
            ))}
            <FormControl sx={styles.viewSelectForm}>
              <InputLabel id="select-label">View</InputLabel>
              <Select
                labelId="select-view-label"
                id="select-view"
                value={selectedView}
                sx={styles.viewSelectSelect}
                label="View"
                onChange={(e) => setSelectedView(e.target.value)}
              >
                {Object.keys(REQUEST_VIEWS).map((view, i) => (
                  <MenuItem value={REQUEST_VIEWS[view]} key={i}>
                    {toTitleCase(REQUEST_VIEWS[view])}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>
      </Grid>

      <TabContext value={selectedTab}>
        <Box sx={styles.tabList}>
          <TabList
            onChange={(e, newValue) => {
              setSelectedTab(newValue);
            }}
            textColor="secondary"
            indicatorColor="secondary"
          >
            {tabs.map((tab, i) => (
              <Tab
                label={tab.value}
                value={tab.value}
                icon={<></>}
                iconPosition="end"
                sx={styles.tabStyle}
                key={i}
              />
            ))}
          </TabList>
        </Box>
        {tabs.map((tab, i) => (
          <TabPanel value={tab.value} key={i}>
            {tab.component}
          </TabPanel>
        ))}
      </TabContext>
      <Box sx={styles.dataGridBoxStyles(colors)}></Box>
    </Box>
  );
};

export default Requests;
