import {
  Box,
  Divider,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { refreshDialogStyles } from "../../../styles";
import { tokens } from "../../../../../theme";
import { stackCenterGap } from "../../../../../styles/utilStyles";
import {
  IMAGE_REPO_PREFIX,
  IMAGE_REPO_SUFFIX,
  RMO_ARTIFACTS_ENUM,
} from "../../../../../utils/constants";
import { detectChange } from "../helperFunctions";

const CustomisableServiceItem = ({
  idx,
  originalReleaseManifest,
  updatedReleaseManifest,
  setUpdatedReleaseManifest,
  service,
  artifactType,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <>
      {idx ? <Divider /> : <></>}
      <Box sx={refreshDialogStyles.serviceItemStack}>
        <Typography variant="h5" sx={refreshDialogStyles.serviceTypography}>
          {service}
        </Typography>
        <Box sx={stackCenterGap} />
        {artifactType === RMO_ARTIFACTS_ENUM ? (
          <Select
            autoFocus
            size="small"
            value={updatedReleaseManifest[artifactType][service].valueFrom}
            onChange={(e) => {
              setUpdatedReleaseManifest((prev) => {
                const curr = structuredClone(prev);
                curr[artifactType][service].valueFrom = e.target.value;
                return curr;
              });
            }}
            style={refreshDialogStyles.artifactSelectStyles}
          >
            <MenuItem value={"tag"}>tag</MenuItem>
            <MenuItem value={"s3Url"}>s3Url</MenuItem>
          </Select>
        ) : (
          <Select
            autoFocus
            size="small"
            value={updatedReleaseManifest[artifactType][service]?.repository}
            onChange={(e) => {
              setUpdatedReleaseManifest((prev) => {
                const curr = structuredClone(prev);
                curr[artifactType][service].repository = e.target.value;
                return curr;
              });
            }}
            style={refreshDialogStyles.repoSelectStyles(
              detectChange(
                "repository",
                originalReleaseManifest,
                updatedReleaseManifest,
                artifactType,
                service
              ),
              colors
            )}
          >
            {IMAGE_REPO_SUFFIX.map((suff) => (
              <MenuItem
                value={`${IMAGE_REPO_PREFIX[artifactType]}${suff}`}
                key={suff}
              >{`${IMAGE_REPO_PREFIX[artifactType]}${suff}`}</MenuItem>
            ))}
          </Select>
        )}
        <TextField
          autoFocus
          margin="dense"
          inputProps={{
            style: refreshDialogStyles.tagTextFieldInputStyle(
              detectChange(
                "name",
                originalReleaseManifest,
                updatedReleaseManifest,
                artifactType,
                service
              ),
              colors
            ),
          }}
          value={updatedReleaseManifest[artifactType][service]?.name}
          variant="outlined"
          size="small"
          sx={refreshDialogStyles.textFieldStyle("170px")}
          onChange={(e) =>
            setUpdatedReleaseManifest((prev) => {
              const curr = structuredClone(prev);
              curr[artifactType][service].name = e.target.value;
              return curr;
            })
          }
        />
        <TextField
          autoFocus
          margin="dense"
          inputProps={{
            style: refreshDialogStyles.tagTextFieldInputStyle(
              detectChange(
                "tag",
                originalReleaseManifest,
                updatedReleaseManifest,
                artifactType,
                service
              ),
              colors
            ),
          }}
          value={
            updatedReleaseManifest[artifactType][service][
              updatedReleaseManifest[artifactType][service].valueFrom
            ]
          }
          variant="outlined"
          size="small"
          sx={refreshDialogStyles.tagTextFieldStyle("80px")}
          onChange={(e) =>
            setUpdatedReleaseManifest((prev) => {
              const curr = structuredClone(prev);
              curr[artifactType][service][
                updatedReleaseManifest[artifactType][service]?.valueFrom
              ] = e.target.value;
              return curr;
            })
          }
        />
      </Box>
    </>
  );
};

export default CustomisableServiceItem;
