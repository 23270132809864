export const commonDataGridStyles = (colors) => {
  return {
    "& .MuiDataGrid-root": {
      border: "none",
    },
    "& .MuiDataGrid-cell": {
      borderBottom: "none",
    },
    "& .name-column--cell": {
      color: colors.green[300],
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: colors.primary[600],
      borderBottom: "none",
    },
    "& .MuiDataGrid-virtualScroller": {
      backgroundColor: colors.primary[700],
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
      backgroundColor: colors.primary[700],
    },
    "& .MuiCheckbox-root": {
      color: `${colors.green[200]} !important`,
    },
  };
};

export const dataGridFooterStatusStyles = (color) => {
  return {
    typography: "h5",
    m: "3px",
    borderRadius: 1,
    bgcolor: color,
    padding: "2px",
    paddingX: "4px",
    fontWeight: 600,
  };
};

export const dataGridButtonTypography = (colors, fontWeight = 600) => ({
  color: colors.grey[100],
  fontWeight: fontWeight,
  ml: "5px",
  mr: "5px",
});
