import { useLazyFetch, useLazyPost } from "../utils/apiclient";
import { devopsUrl } from "../utils/urls";
import { getHeaders } from "../utils/apiclient";

export const getReleasesUrl = (appGroup) =>
  `${devopsUrl}/applications/app-groups/${appGroup}/release-labels`;

export const useGetAppGroups = () => {
  return useLazyFetch(`${devopsUrl}/applications/app-groups`, {
    headers: getHeaders(),
  });
};

export const useGetReleases = (appGroup) => {
  return useLazyFetch(getReleasesUrl(appGroup), {
    headers: getHeaders(),
  });
};

export const usePatchReleaseStatus = (appGroup) => {
  return useLazyPost(getReleasesUrl(appGroup), {
    headers: getHeaders(),
    method: "PATCH",
  });
};

export const useGetPatch = (appGroup) => {
  return useLazyFetch(getReleasesUrl(appGroup), {
    headers: getHeaders(),
  });
};

export const useCreateLabel = (appGroup) => {
  return useLazyPost(getReleasesUrl(appGroup), {
    headers: getHeaders(),
    method: "POST",
  });
};

export const usePatchLabel = (appGroup) => {
  return useLazyPost(getReleasesUrl(appGroup), {
    headers: getHeaders(),
    method: "POST",
  });
};
