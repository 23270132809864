import Cache from "./Cache";

const origin = window.location.origin;
let backendUrl, authUrl, qaosUrl;
if (!origin.startsWith("http://localhost")) {
  backendUrl = window.location.origin.replace("://", "://api.");
  authUrl = backendUrl;
  qaosUrl = backendUrl;
} else {
  if (sessionStorage.getItem("isNotLocalBackend") === "true") {
    backendUrl = "https://api.dev.convoy.tessell.cloud";
    authUrl = backendUrl;
    qaosUrl = backendUrl;
  } else {
    backendUrl = "http://localhost:8001";
    authUrl = "http://localhost:8002";
    qaosUrl = "http://localhost:8004";
  }
}
export { backendUrl, authUrl, qaosUrl };

export const devopsUrl = `${backendUrl}/devops`;

export const githubUrl = "https://github.com/TessellDevelopment";

export const grafanaUrl = "https://grafana.proxy.convoy.tessell.cloud/";

export const jiraMainLabelDashbord =
  "https://tessell.atlassian.net/jira/dashboards/10106";
