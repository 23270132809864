import React from "react";
import ReactDOM from "react-dom/client";
import { LicenseInfo } from "@mui/x-license-pro";

import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

LicenseInfo.setLicenseKey(
  "fdd5f552651b2a312d00e54b466024f1Tz02MTA1MSxFPTE3MDk1MTAxODU2ODgsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);
