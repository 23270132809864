import React from "react";
import { TextField, Autocomplete, MenuItem } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";

const styles = {
  AutocompleteDefaultStyles: { m: 1, width: "20%" },
  ChipStyles: (colors) => ({
    bgcolor: colors.green[700],
    opacity: 0.8,
    fontWeight: 700,
  }),
  MenuItemSyles: { justifyContent: "space-between" },
};

const MultiSelect = ({
  allItems,
  setSelectedItems,
  limit = -1,
  label = "",
  sx,
  textFieldProps = {},
  chipSx = { height: "19px" },
  populateItemsWhenEmpty = true,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Autocomplete
      sx={sx || styles.AutocompleteDefaultStyles}
      multiple
      limitTags={limit}
      options={allItems || []}
      getOptionLabel={(option) => option}
      disableCloseOnSelect
      onChange={(event, value) => {
        if (setSelectedItems) {
          setSelectedItems(
            !populateItemsWhenEmpty || value?.length ? value : allItems
          );
        }
      }}
      ChipProps={{
        sx: { ...styles.ChipStyles(colors), ...chipSx },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          {...textFieldProps}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <MenuItem
          {...props}
          key={option}
          value={option}
          sx={styles.MenuItemSyles}
        >
          {option}
          {selected ? <CheckIcon color="info" /> : null}
        </MenuItem>
      )}
    />
  );
};

export default MultiSelect;
