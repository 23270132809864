/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { tokens } from "../../../theme";
import { UserContext } from "../../../contexts";
import { Box, Typography, useTheme } from "@mui/material";
import {
  useGetCPRequestsToUser,
  usePatchCPRequest,
} from "../../../api/requests";
import RequestCard from "../RequestCard";
import Notification from "../../../components/Notification";
import { REQUEST_VIEWS } from "../../../utils/constants";
import styles from "../styles";

const getRequestsParams = (view) =>
  view === REQUEST_VIEWS.requestsMade ? "/self" : "";

const REQUEST_CARD_KEYS = (view) => [
  view === REQUEST_VIEWS.requestsReceived
    ? { key: "requester", name: "Requested By" }
    : { key: "approver", name: "Requested To" },
  { key: "projectName", name: "Name" },
  { key: "jiraId", name: "JIRA" },
  { key: "globalEnvironmentName", name: "Global Env" },
  { key: "releaseLabel", name: "Release Label" },
  { key: "servicePlan", name: "Plan" },
  { key: "cloudType", name: "Infra Cloud" },
  { key: "dataPlaneClouds", name: "DP Clouds" },
  { key: "justification", name: "Justification" },
];

const CPRequests = ({
  view,
  setConfirmationDialog,
  requestFilter,
  refreshToggle,
  setRefreshToggle,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const loggedInUser = useContext(UserContext);

  const [notify, setNotify] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [requests, setRequests] = useState([]);

  const {
    postData: getRequests,
    response: getRequestsResp,
    isLoading: isLoadingRequests,
  } = useGetCPRequestsToUser();

  const {
    postData: patchRequest,
    response: patchRequestResp,
    responseStatus: patchRequestsRespStatus,
  } = usePatchCPRequest();

  useEffect(() => {
    const message =
      patchRequestResp?.message ||
      patchRequestResp?.detail?.message ||
      patchRequestResp?.detail;
    if (message?.length && typeof patchRequestsRespStatus === "number") {
      setMessage(message);
      setSeverity(patchRequestsRespStatus === 200 ? "success" : "error");
      setNotify(true);
    }
    setRefreshToggle((prev) => !prev);
  }, [patchRequestResp, patchRequestsRespStatus]);

  useEffect(() => {
    getRequests(getRequestsParams(view));
  }, [refreshToggle, view]);

  useEffect(() => {
    if (getRequestsResp?.response) {
      setRequests(getRequestsResp?.response);
    }
  }, [getRequestsResp]);

  const handleRequestApproval = (status, id) => {
    setConfirmationDialog({
      isOpen: true,
      content: `You are going to mark this request as ${status}`,
      handleDialogConfirm: () => {
        patchRequest({ status: status }, `/${id}`);
      },
    });
  };

  const requestCards = requests
    ?.map((request, i) => {
      return (
        requestFilter?.[request?.status] && (
          <RequestCard
            key={i}
            view={view}
            request={request}
            handleRequestApproval={handleRequestApproval}
            requestCardKeys={REQUEST_CARD_KEYS}
            approveDisabled={
              loggedInUser?.data?.quotas?.controlPlane?.allocated <=
              loggedInUser?.data?.quotas?.controlPlane?.consumed
            }
            approveToolTipText={`Quota is exhausted ${loggedInUser?.data?.quotas?.controlPlane?.consumed}/${loggedInUser?.data?.quotas?.controlPlane?.allocated}`}
          />
        )
      );
    })
    .filter(Boolean);
  return (
    <div style={{ width: "100%" }}>
      <Notification
        notify={notify}
        severity={severity}
        setNotify={setNotify}
        message={message}
      />
      {requestCards?.length && !isLoadingRequests ? (
        <Box sx={styles.requestCardsParentGrid}>{requestCards}</Box>
      ) : (
        <Typography>No Requests</Typography>
      )}
    </div>
  );
};

export default CPRequests;
