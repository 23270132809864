import utilColors from "../../styles/utilColors";
import { commonDataGridStyles } from "../../styles/DataGridStyles";

export const indexStyles = {
  showAllEnvsBox: {
    mt: "3px",
    ml: "-10px",
    p: "5px",
    width: "140px",
  },
  mainBox: { mx: "5px" },
  gridContainer: {
    mt: "-10px",
    height: "10vh",
    alignItems: "center",
  },
  dataGridBoxStyles: (colors) => ({
    height: "86.7vh",
    width: "99.2%",
    ...commonDataGridStyles(colors),
  }),
  diableGridActionItem: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  diableGridActionItemTypography: (colors) => ({
    color: colors.grey[500],
  }),
};

export const refreshDialogStyles = {
  checkBoxStyle: {
    ml: "-5px",
    mr: "5px",
  },
  searchBar: { width: "50%", my: "5px" },
  circularProgressStyle: { mx: "200px" },
  artifactAccordionStyle: (colors) => ({
    backgroundColor: colors.primary[700],
    width: "1000px",
  }),
  artifactAccordionSummaryStyle: {
    boxShadow: 5,
  },
  addNewServiceButtonStyle: { p: "0px", mr: "15px" },
  artifactTypeTypography: {
    fontWeight: 600,
  },
  serviceItemStack: {
    px: "10px",
    mt: "-3px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1rem",
  },
  serviceTypography: {
    fontWeight: 600,
    mx: "-5px",
    component: "span",
  },
  repoSelectStyles: (flag, colors) => ({
    color: flag ? colors.green[500] : utilColors.white,
    width: "120px",
  }),
  artifactSelectStyles: {
    width: "70px",
  },
  baseConfigBranchtextFieldStyle: {
    width: "150px",
    mt: "5px",
  },
  tagTextFieldInputStyle: (flag, colors) => ({
    textAlign: "center",
    color: flag ? colors.green[500] : utilColors.white,
  }),
  textFieldStyle: (width) => ({
    boxShadow: 1,
    width: width,
  }),
  rmoKeyTextFieldStyle: (width) => ({
    boxShadow: 1,
    width: width,
    ml: "-10px",
  }),
  tagTextFieldStyle: (width) => ({
    boxShadow: 1,
    width: width,
    mr: "13px",
  }),
  cancelCustomKeyButtonStyle: {
    ml: "-28px",
    mr: "-24px",
  },

  confirmationServiceTypography: {
    fontWeight: 600,
    mx: "-5px",
    width: "20%",
  },
  diffBox: {
    width: "80%",
  },
  diffRow: {
    mt: "10px",
    display: "flex",
    flexDirection: "row",
  },
  diffItemKey: {
    width: "14%",
  },
  diffItem: (hue) => ({
    width: "40%",
    color: hue[500],
  }),
  arrowStyle: {
    mr: "4%",
  },
};

export const requestCPDialogStyles = {
  gridContainer: {
    padding: "7px",
    width: "50vw",
  },
  multiSelect: {
    height: "10px",
  },
};
