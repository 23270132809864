import { statusEnum } from "./CustomEnvFooter";
import moment from "moment";
import { timeFormat } from "../../utils/constants";
import { convertUTCTimeToLocalTime } from "../../utils/dateUtils";

export const isValidRequestCPPayload = (payload) => {
  return Object.keys(payload).filter((key) => payload[key] === "").length === 0;
};

export const roles = {
  OWNER: "TENANT_ENVIRONMENT_OWNER",
  LEAD: "TENANT_ENVIRONMENT_LEAD",
  DEVELOPER: "TENANT_ENVIRONMENT_DEVELOPER",
};

export const notifyMinOptions = [10, 15, 20, 30, 45, 60];

export const parseRequestCPPayload = (payload) => {
  return Object.keys(payload).reduce(
    (acc, key) => (payload[key] ? { ...acc, [key]: payload[key] } : acc),
    {}
  );
};

export const getEnvsSimplified = (envs, users) => {
  return envs.map((env) => {
    let newEnvObj = structuredClone(env);

    newEnvObj.memberRole = env?.member_role?.replace("TENANT_ENVIRONMENT_", "");
    newEnvObj.members = {};
    newEnvObj.indexedMembers = [];
    env?.members?.forEach((memberObj) => {
      newEnvObj.members[memberObj?.email] = memberObj?.role_name?.replace(
        "TENANT_ENVIRONMENT_",
        ""
      );
      newEnvObj.indexedMembers.push(memberObj.email);
    });

    const repoPort = 5432;
    newEnvObj.repoConfig = `
      SPRING_DATASOURCE_PASSWORD=${env?.meta?.repository_info?.schema_password}
      SPRING_DATASOURCE_URL=jdbc:postgresql://${env?.meta?.repository_info?.schema_repo_tailscale_ip}:${repoPort}/${env?.meta?.repository_info?.schema_name_tessell}
      SPRING_DATASOURCE_USERNAME=${env?.meta?.repository_info?.schema_username}
      TESSELL_REPO_DATABASE_NAME=${env?.meta?.repository_info?.schema_name_tessell}
      TESSELL_REPO_DATABASE_PASSWORD=${env?.meta?.repository_info?.schema_password}
      TESSELL_REPO_DATABASE_USERNAME=${env?.meta?.repository_info?.schema_username}
      TESSELL_REPO_HOST_IP=${env?.meta?.repository_info?.schema_repo_tailscale_ip}
      TESSELL_REPO_HOST_PORT=${repoPort}
    `
      .replace(/  +/g, "")
      .replace(/^\s*[\r\n]/gm, "");

    newEnvObj.autoHibernateEnabled =
      env?.config?.hibernate?.enableAutomaticHibernation;
    newEnvObj.autoHibernateSchedule = convertUTCTimeToLocalTime(
      env?.config?.hibernate?.schedule?.utctime
    );
    newEnvObj.autoHibernateRepeat = Array(7)
      .fill(0)
      .map((_, i) => env?.config?.hibernate?.schedule?.repeat.includes(i));
    newEnvObj.autoHibernateNotify = notifyMinOptions.map((minutes) =>
      env?.config?.hibernate?.schedule?.notifyBeforeMinutes.includes(minutes)
    );

    newEnvObj.autoWakeupEnabled = env?.config?.wakeup?.enableAutomaticWakeup;
    newEnvObj.autoWakeupSchedule = convertUTCTimeToLocalTime(
      env?.config?.wakeup?.schedule?.utctime
    );
    newEnvObj.autoWakeupRepeat = Array(7)
      .fill(0)
      .map((_, i) => env?.config?.wakeup?.schedule?.repeat.includes(i));
    newEnvObj.autoWakeupNotify = notifyMinOptions.map((minutes) =>
      env?.config?.wakeup?.schedule?.notifyBeforeMinutes.includes(minutes)
    );

    newEnvObj.autoDeleteEnabled =
      !env?.config?.dbServicesAutoDelete?.skipDeleteProtected;
    newEnvObj.autoDeleteHours =
      env?.config?.dbServicesAutoDelete?.deleteAfterHours;

    newEnvObj.url = env?.meta?.tenant_api_endpoint?.replace("api.", "");

    newEnvObj.createdBy = env?.owner;

    newEnvObj.createdOn = moment(env?.date_created)?.format(timeFormat);
    newEnvObj.lastModifiedAt = moment(env?.date_modified)?.format(timeFormat);
    newEnvObj.age = Math.floor(
      moment.duration(Date.now() - Date.parse(env?.date_created)).asDays()
    );

    newEnvObj.addableMembers = users.filter(
      (member) =>
        !newEnvObj.indexedMembers
          .filter((email) => email in newEnvObj.members)
          .includes(member.email)
    );

    return newEnvObj;
  });
};

export const getEnvStatusCount = (envs) => {
  const statusCount = {};
  let nonOthers = 0;
  statusEnum.forEach((stat) => {
    statusCount[stat] = envs?.reduce((total, env) => {
      return total + env?.status?.includes(stat);
    }, 0);
    nonOthers += statusCount[stat];
  });
  statusCount["TOTAL"] = envs?.length;
  statusCount["OTHERS"] = envs?.length - nonOthers;
  statusCount.OTHERSList = {};
  statusCount.FAILEDList = {};
  envs?.forEach((env) => {
    if (env?.status.includes("FAILED")) {
      statusCount.FAILEDList[env?.status] =
        1 + (statusCount.FAILEDList[env?.status] || 0);
    } else if (!statusEnum.includes(env?.status)) {
      statusCount.OTHERSList[env?.status] =
        1 + (statusCount.OTHERSList[env?.status] || 0);
    }
  });
  return statusCount;
};
