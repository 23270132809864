import { MenuItem, Select, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import ConvoyDialog from "../../../components/ConvoyDialog";
import { tokens } from "../../../theme";

const UpdateReleaseStatusDialog = ({
  updateReleaseStatusDialog,
  patchReleaseStatus,
  resetDialog,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    setSelectedStatus(updateReleaseStatusDialog?.current_status);
  }, [updateReleaseStatusDialog]);

  const handleDialogConfirm = () => {
    patchReleaseStatus(
      { parent_status: selectedStatus },
      `/${updateReleaseStatusDialog.release}`
    );
    resetDialog();
  };

  return (
    <ConvoyDialog
      open={updateReleaseStatusDialog.isOpen}
      handleDialogConfirm={() =>
        handleDialogConfirm(updateReleaseStatusDialog.release)
      }
      resetDialog={resetDialog}
      title={"Update Status"}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography component="span" variant="h5">
          Set new status of {updateReleaseStatusDialog.release}:
        </Typography>
        <Select
          autoFocus
          size="small"
          value={selectedStatus}
          onChange={(e) => {
            setSelectedStatus(e.target.value);
          }}
        >
          {updateReleaseStatusDialog?.options?.map((label, i) => (
            <MenuItem value={label} key={i}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </ConvoyDialog>
  );
};

export default UpdateReleaseStatusDialog;
