import { commonDataGridStyles } from "./DataGridStyles";

export const tabbedDataGridPage = {
  // tab parent page and tab styles
  mainBox: {
    m: "-40px 16px 0 5px",
  },
  tabContainer: {
    width: "100%",
    typography: "body1",
    height: "100%",
  },
  tabListBox: {
    borderBottom: 1,
    borderColor: "divider",
  },
  tabList: {
    height: "55px",
  },

  // tab content page
  tabContentMainBox: {
    mx: "-25px",
    mt: "-40px",
    mb: "-30px",
  },
  topButtonStack: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  gridContainer: {
    mt: "2vh",
    ml: "5px",
    mb: "-10px",
    alignItems: "center",
    height: "12vh",
  },
  dataGridBoxStyles: (colors) => ({
    mt: "-20px",
    height: "84vh",
    width: "99.4%",
    ...commonDataGridStyles(colors),
  }),
};
