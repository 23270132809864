import { Box, Divider, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { red, green, orange, yellow } from "@mui/material/colors";
import { simpleCardStyle } from "../../../styles/cardStyles";
import styles from "./styles";
import { MsSqlServer, PostgreSql, Oracle, MySql } from "../../../icons";
import Chart from "react-apexcharts";
import utilColors from "../../../styles/utilColors";

const getIcons = (appName) => {
  const iconMap = {
    POSTGRESQL: <PostgreSql />,
    SQLSERVER: <MsSqlServer />,
    ORACLE: <Oracle />,
    MYSQL: <MySql />,
  };
  return iconMap[appName];
};

const getBorderColor = (failedTestPriority) => {
  if (failedTestPriority?.P0 > 0) {
    return red[800];
  } else if (failedTestPriority?.P1 > 0) {
    return orange[800];
  } else if (failedTestPriority?.P2 > 0) {
    return yellow[800];
  }
  return green[700];
};

const AppHealthCard = ({ appName, testResult }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const series = [
    testResult?.passed || 0,
    testResult?.failedTestPriority?.P0 || 0,
    testResult?.failedTestPriority?.P1 || 0,
    testResult?.failedTestPriority?.P2 || 0,
    testResult.skipped || 0,
  ];

  const chartOptions = {
    colors: [green[500], red[700], orange[700], yellow[600], colors.grey[400]],
    labels: ["Passed", "Failed P-0", "Failed P-1", "Failed P-2", "Skipped"],
    legend: {
      show: true,
      position: "right",
      labels: {
        colors: utilColors.white,
        useSeriesColors: false,
      },
    },
  };

  return (
    <Box
      sx={simpleCardStyle(
        colors,
        getBorderColor(testResult?.failedTestPriority)
      )}
    >
      <Box sx={styles.cardSummaryItemStyle}>
        <Box sx={styles.cardLabelBoxStyle}>
          <Typography variant="h4">{appName}</Typography>
          <Box sx={styles.appIconStyle}>{getIcons(appName)}</Box>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box sx={styles.cardDetailsBoxStyle}>
          <Chart
            options={chartOptions}
            series={series}
            width={350}
            type="donut"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AppHealthCard;
