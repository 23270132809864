import { VULNERABILITY_STATUS } from "../../utils/constants";
export const adaptAllVulnerabilityFilterOptions = (input) => {
  Object.keys(input).reduce((filterOption, output) => {
    output[filterOption] = {
      ...input[filterOption],
      selectedItems: [],
    };
  }, {});
};

export const adaptVulnerabilities = (input) => {
  return input?.map((vulnerability) => ({
    ...vulnerability,
    ["status"]: vulnerability?.excludedForAll
      ? VULNERABILITY_STATUS?.APPROVED
      : vulnerability?.status,
  }));
};
