export const triggerAutomationPayload = `{
  "automationConfig": {
      "branch": "main",
      "cucumberConfig": {
          "featureFiles": [
              "src/test/resources/tessell/......"
          ]
      }
  },
  "environmentConfig": {
      "apiURL": "https://........",
      "tenantId": "00000000-0000-0000-0000-000000000000",
      "users": {
          "default": {
              "emailId": "......",
              "password": "......",
              "apiKey": "......"
          }
      }
  }
}`;
