import moment from "moment";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Switch,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts";
import ConvoyDialog from "../../../components/ConvoyDialog";
import CommitsList from "./CommitsList";
import { useGetPatch } from "../../../api/releases";
import { tokens } from "../../../theme";
import { ExpandMore } from "@mui/icons-material";
import styles from "../styles";
import {
  SCHEDULED_PATCH_THRESHOLD_TIME,
  PR_BUILD_STATUS,
  USER_ROLES,
} from "../../../utils/constants";

export const getPatchPayload = (repositories, commitsNotIncluded) => {
  const payload = [];
  repositories.forEach((repo) => {
    const commits = [];
    repo?.commits.forEach((commit, i) => {
      i >= commitsNotIncluded[repo.repo_name] &&
        commits.push(structuredClone(commit));
    });
    commits.length &&
      payload.push({
        repo_name: repo.repo_name,
        commits: commits,
      });
  });
  return { repositories: payload };
};

const PatchLabelDialog = ({
  selectedAppGroup,
  patchLabelDialog,
  patchLabel,
  resetDialog,
}) => {
  const loggedInUser = useContext(UserContext);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [patchQueryString, setPatchQueryString] = useState("");
  const [commitsNotIncluded, setCommitsNotIncluded] = useState({});
  const [repositories, setRepositories] = useState([]);
  const [expandedAccordions, setExpandedAccordions] = useState(new Set());

  const [isUserReleaseOwner, setIsUserReleaseOwner] = useState(false);
  useEffect(() => {
    setIsUserReleaseOwner(
      loggedInUser?.data?.roles?.includes(USER_ROLES.RELEASE_OWNER)
    );
  }, [loggedInUser?.data]);

  const {
    postData: getPatch,
    response: getPatchResp,
    isLoading: isLoadingPatch,
  } = useGetPatch(selectedAppGroup);

  useEffect(() => {
    if (getPatchResp?.repositories) {
      setRepositories(getPatchResp.repositories);
      const reposMap = {};
      getPatchResp.repositories.forEach((repository, i) => {
        reposMap[repository?.repo_name] = 0;
      });
      setCommitsNotIncluded(reposMap);
    }
  }, [getPatchResp]);

  useEffect(() => {
    if (patchLabelDialog.isOpen) {
      getPatch(
        `/${patchLabelDialog.release}/unlabelled-commits${patchQueryString}`
      );
    }
  }, [patchLabelDialog, patchQueryString]);

  useEffect(() => {
    if (patchLabelDialog.isOpen) {
      setPatchQueryString("");
    }
  }, [patchLabelDialog]);

  const handleDialogConfirm = () => {
    if (isUserReleaseOwner) {
      const payload = getPatchPayload(repositories, commitsNotIncluded);
      patchLabel(payload, `/${patchLabelDialog.release}/patches`);
    }
    resetDialog();
  };

  const isConfirmEnabled = () => {
    if (!isUserReleaseOwner) {
      return true;
    }
    const totalCommitsSelected = repositories?.filter(
      (repository) =>
        commitsNotIncluded[repository?.repo_name] !==
        repository?.commits?.length
    )?.length;

    const nonSucessfulHeadCommits = repositories?.filter((repository) => {
      const omittedCommits = commitsNotIncluded[repository?.repo_name];
      const totalCommits = repository?.commits?.length;
      return (
        omittedCommits !== totalCommits &&
        repository?.commits?.[omittedCommits]?.build_status !==
          PR_BUILD_STATUS[1]
      );
    })?.length;
    return totalCommitsSelected && !nonSucessfulHeadCommits;
  };

  if (!repositories?.length) {
    return (
      <ConvoyDialog
        open={patchLabelDialog.isOpen}
        handleDialogConfirm={resetDialog}
        resetDialog={resetDialog}
        title={"Create a new Patch"}
        confirmBtnText={"OK"}
        cancelBtnText={" "}
      >
        No available commits to create a new patch.
      </ConvoyDialog>
    );
  }
  return (
    <ConvoyDialog
      open={patchLabelDialog.isOpen}
      handleDialogConfirm={() => handleDialogConfirm(patchLabelDialog.release)}
      resetDialog={resetDialog}
      title={"Create a new Patch"}
      disabled={!isConfirmEnabled()}
      additionalActions={
        isUserReleaseOwner
          ? [
              {
                buttonText: "SELECT ALL",
                onClick: () => {
                  const reposMap = {};
                  repositories.forEach((repository, i) => {
                    reposMap[repository?.repo_name] = 0;
                  });
                  setCommitsNotIncluded(reposMap);
                },
              },
              {
                buttonText: "UNSELECT ALL",
                onClick: () => {
                  const reposMap = {};
                  repositories.forEach((repository, i) => {
                    reposMap[repository?.repo_name] =
                      repository?.commits?.length;
                  });
                  setCommitsNotIncluded(reposMap);
                },
              },
              {
                buttonText: "EXPAND ALL",
                onClick: () => {
                  setExpandedAccordions(
                    new Set([...Array(repositories?.length || 0).keys()])
                  );
                },
              },
              {
                buttonText: "COLLAPSE ALL",
                onClick: () => {
                  setExpandedAccordions(new Set([]));
                },
              },
            ]
          : null
      }
    >
      <Box sx={styles.scheduledPatchStack}>
        <Typography variant="h5">Scheduled Patch</Typography>
        <Switch
          color="secondary"
          checked={patchQueryString !== ""}
          onChange={() => {
            setPatchQueryString((prev) =>
              prev === ""
                ? `?committedBefore=${moment()
                    .utc()
                    .format("YYYY-MM-DD")}-${SCHEDULED_PATCH_THRESHOLD_TIME}`
                : ""
            );
          }}
          size="small"
        />
      </Box>
      {repositories?.map((repository, i) => {
        return (
          <Accordion
            key={i}
            expanded={expandedAccordions.has(i)}
            onChange={() =>
              setExpandedAccordions((prev) =>
                prev.has(i)
                  ? new Set([...prev].filter((idx) => idx !== i))
                  : new Set([...prev, i])
              )
            }
            sx={styles.repoAccordionStyle(colors)}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`panel${i}c-content`}
              id={`panel${i}c-header`}
            >
              <Typography variant="h5">{repository?.repo_name}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <CommitsList
                commits={repository?.commits}
                repoName={repository?.repo_name}
                commitsNotIncluded={commitsNotIncluded}
                setCommitsNotIncluded={setCommitsNotIncluded}
                isUserReleaseOwner={isUserReleaseOwner}
              />
            </AccordionDetails>
          </Accordion>
        );
      })}
    </ConvoyDialog>
  );
};

export default PatchLabelDialog;
