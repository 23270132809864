import { Stack, TextField, Typography, useTheme } from "@mui/material";
import ConvoyDialog from "../../../../components/ConvoyDialog";
import { tokens } from "../../../../theme";
import { useState } from "react";
import { NEXUS_REPO, TENANT_UI } from "../../../../utils/constants";

const UiRefresh = ({ env, setRefreshType, resetDialog, refreshEnv }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [value, setValue] = useState("");
  const handleDialogConfirm = () => {
    refreshEnv({
      url: env?.url,
      releaseManifestOverride: {
        artifacts: {
          [TENANT_UI.rmoKey]: {
            tag: value,
            repository: NEXUS_REPO.devPullRepo,
          },
        },
      },
      upgradeTfAndDpLibs: false,
      updateServices: false,
      updateUi: true,
      updateSeedData: false,
    });
    resetDialog();
  };
  return (
    <ConvoyDialog
      open={true}
      title="Refresh environment"
      handleDialogConfirm={handleDialogConfirm}
      resetDialog={resetDialog}
      onCancelClick={() => setRefreshType("simple")}
      cancelBtnText="Back"
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography component="span" variant="h5">
          Refresh only the UI for {env.name} ?
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <TextField
          fullWidth
          label="Custom Tag"
          placeholder="TS-????"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      </Stack>
    </ConvoyDialog>
  );
};

export default UiRefresh;
