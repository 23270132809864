import { useLazyFetch } from "../utils/apiclient";
import { devopsUrl } from "../utils/urls";
import { getHeaders } from "../utils/apiclient";

export const pendingDoubleCommitMergesUrl = `${devopsUrl}/git-metadata/double-commits`;

export const useGetDCPrs = () => {
  return useLazyFetch(pendingDoubleCommitMergesUrl, {
    headers: getHeaders(),
  });
};

export const useGetPrBuilds = () => {
  return useLazyFetch(`${devopsUrl}/git-metadata/commits/unlabelled`);
};
