import utilColors from "../../styles/utilColors";

export const styles = {
  mainBox: {
    m: "10px",
  },
  iframe: {
    background: utilColors.white,
    width: "100%",
    height: "87.5vh",
  },
};
