import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Grid, Typography, useTheme, Menu, MenuItem, Box } from "@mui/material";
import { tokens } from "../theme";
import { commonButtonStyles } from "../styles/buttonStyles";
const styles = (colors) => ({
  button: {
    width: "71%",
    borderRadius: "4px 0px 0px 4px",
    ...commonButtonStyles(colors, "0"),
  },
  dropDown: {
    borderRadius: "0 4px 4px 0",
    position: "relative",
    ...commonButtonStyles(colors, "0"),
  },
  arrow: {
    position: "absolute",
    left: "-7px",
    top: "5px",
  },
  typography: {
    ml: "5px",
    mr: "5px",
  },
});
const DropDownButton = ({ row, disabled, handleHibernateWakeUp }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const currentOption =
    row?.status === "HIBERNATE_FAILED" ? "HIBERNATE" : "WAKE UP";
  const [buttonContent, setButtonContent] = useState(currentOption);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect = (actionValue) => {
    setButtonContent(actionValue);
    handleClose();
  };
  return (
    <Grid display="flex" width={"100%"} marginLeft={"15%"}>
      <Box
        component="button"
        sx={styles(colors).button}
        disabled={disabled}
        onClick={() => handleHibernateWakeUp(row, buttonContent)}
      >
        <Typography
          color={colors.grey[100]}
          fontWeight={600}
          sx={styles(colors).typography}
        >
          {buttonContent}
        </Typography>
      </Box>
      <Box
        component="button"
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={styles(colors).dropDown}
        disabled={disabled}
      >
        <ArrowDropDownIcon sx={styles(colors).arrow} />
      </Box>
      <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {["WAKE UP", "HIBERNATE"].map((action) => (
          <MenuItem key={action} onClick={() => handleSelect(action)}>
            {action}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

export default DropDownButton;
