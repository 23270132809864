import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../../../theme";
import { toTitleCase } from "../../../../../utils/helperFunctions";
import { ExpandMore } from "@mui/icons-material";
import CustomisableServiceItem from "./CustomisableServiceItem";
import { refreshDialogStyles } from "../../../styles";
import ConfirmationServiceItem from "./ConfirmationServiceItem";
import { detectChange } from "../helperFunctions";
import { stackCenterGap } from "../../../../../styles/utilStyles";
import AdditionalServiceItem from "./AdditionalServiceItem";
import { PlaylistAddRounded } from "@mui/icons-material";

const ArtifactTypeAccordion = ({
  isConfirmationDialog,
  artifactType,
  originalReleaseManifest,
  updatedReleaseManifest,
  setUpdatedReleaseManifest,
  additionalReleaseManifest,
  setAdditionalReleaseManifest,
  visibleServices,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [expandedAccordion, setExpandedAccordion] = useState(false);

  const serviceItems = Object.keys(originalReleaseManifest[artifactType])
    ?.filter((service) => {
      return isConfirmationDialog
        ? detectChange(
            "any",
            originalReleaseManifest,
            updatedReleaseManifest,
            artifactType,
            service
          )
        : visibleServices.includes(service) ||
            visibleServices.includes(
              originalReleaseManifest[artifactType][service]?.name
            );
    })
    ?.map((service, serviceIdx) => {
      const props = {
        key: serviceIdx,
        idx: serviceIdx,
        originalReleaseManifest: originalReleaseManifest,
        updatedReleaseManifest: updatedReleaseManifest,
        setUpdatedReleaseManifest: setUpdatedReleaseManifest,
        service: service,
        artifactType: artifactType,
      };
      return isConfirmationDialog ? (
        <ConfirmationServiceItem {...props} />
      ) : (
        <CustomisableServiceItem {...props} />
      );
    })
    .filter(Boolean);

  const additionalServiceItems = additionalReleaseManifest[artifactType]
    ?.map((_, serviceIdx) => {
      return (
        <AdditionalServiceItem
          key={serviceIdx}
          idx={serviceIdx}
          additionalReleaseManifest={additionalReleaseManifest}
          setAdditionalReleaseManifest={setAdditionalReleaseManifest}
          artifactType={artifactType}
          isConfirmationDialog={isConfirmationDialog}
        />
      );
    })
    .filter(Boolean);

  return (
    <Accordion
      sx={refreshDialogStyles.artifactAccordionStyle(colors)}
      expanded={isConfirmationDialog || expandedAccordion}
      onChange={() => setExpandedAccordion((prev) => !prev)}
    >
      <AccordionSummary
        expandIcon={isConfirmationDialog ? <></> : <ExpandMore />}
        sx={refreshDialogStyles.artifactAccordionSummaryStyle}
      >
        <Stack direction="row" width="100%">
          <Typography
            variant="h5"
            sx={refreshDialogStyles.artifactTypeTypography}
          >
            {toTitleCase(artifactType)}
          </Typography>
          <Box sx={stackCenterGap} />
          {isConfirmationDialog ? (
            <></>
          ) : (
            <IconButton
              sx={refreshDialogStyles.addNewServiceButtonStyle}
              onClick={(e) => {
                e.stopPropagation();
                setExpandedAccordion(true);
                setAdditionalReleaseManifest((prev) => ({
                  ...prev,
                  [artifactType]: [{}, ...prev[artifactType]],
                }));
              }}
            >
              <PlaylistAddRounded />
            </IconButton>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {additionalServiceItems?.length ? additionalServiceItems : <></>}
        {serviceItems?.length ? serviceItems : <></>}
        {serviceItems?.length || additionalServiceItems?.length ? (
          <></>
        ) : (
          <Typography variant="h5">No changes</Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default ArtifactTypeAccordion;
