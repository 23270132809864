import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { borderBox } from "../../../styles/cardStyles";
import styles from "../styles";

import CommitGroupBox from "./CommitGroupBox";

const LabelBox = ({ label, visibleCommitGroups, view }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const commitGroupArray = label?.commitGroups?.filter(
    (commitGroup) =>
      visibleCommitGroups.includes(commitGroup?.name) &&
      commitGroup?.commits?.length
  );
  if (view === "release") {
    commitGroupArray?.sort((a, b) => b.commits.length - a.commits.length);
  }

  const commitGroupBoxes = commitGroupArray?.map((commitGroup, i) => {
    return <CommitGroupBox commitGroup={commitGroup} key={i} />;
  });
  return (
    <Box sx={borderBox(colors)}>
      <Typography variant="h5" sx={styles.boldText}>
        {label?.name}
      </Typography>
      {commitGroupBoxes?.length ? (
        commitGroupBoxes
      ) : (
        <Typography variant="h5" sx={styles.noData}>
          No Data
        </Typography>
      )}
    </Box>
  );
};

export default LabelBox;
