import { useLazyFetch, useLazyPost } from "../utils/apiclient";
import { qaosUrl } from "../utils/urls";
import { getHeaders } from "../utils/apiclient";

export const useGetAvailableLabels = () => {
  return useLazyFetch(`${qaosUrl}/tessell/dev/label-health/release-labels`, {
    headers: { ...getHeaders(), Action: "LIST_LABELS" },
  });
};

export const useGetLabelHealth = (label) => {
  return useLazyFetch(
    `${qaosUrl}/tessell/dev/label-health/release-labels/${label}`,
    {
      headers: { ...getHeaders(), Action: "RUN_DETAILS" },
    }
  );
};
