import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import { refreshDialogStyles } from "../../../styles";
import { tokens } from "../../../../../theme";
import { stackCenterGap } from "../../../../../styles/utilStyles";
import {
  IMAGE_REPO_PREFIX,
  IMAGE_REPO_SUFFIX,
} from "../../../../../utils/constants";
import { Cancel } from "@mui/icons-material";

const AdditionalServiceItem = ({
  idx,
  additionalReleaseManifest,
  setAdditionalReleaseManifest,
  artifactType,
  isConfirmationDialog,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleFieldOnChange = (e, field) => {
    setAdditionalReleaseManifest((prev) => {
      const curr = structuredClone(prev);
      curr[artifactType][idx][field] = e.target.value;
      return curr;
    });
  };

  return (
    <>
      <Box sx={refreshDialogStyles.serviceItemStack}>
        <TextField
          autoFocus
          margin="dense"
          inputProps={{
            style: refreshDialogStyles.tagTextFieldInputStyle(true, colors),
          }}
          variant="outlined"
          size="small"
          sx={refreshDialogStyles.rmoKeyTextFieldStyle("170px")}
          disabled={isConfirmationDialog}
          value={additionalReleaseManifest[artifactType][idx]?.key || ""}
          onChange={(e) => handleFieldOnChange(e, "key")}
        />

        <Box sx={stackCenterGap} />
        <Select
          autoFocus
          size="small"
          disabled={isConfirmationDialog}
          value={additionalReleaseManifest[artifactType][idx]?.repository || ""}
          onChange={(e) => handleFieldOnChange(e, "repository")}
          style={refreshDialogStyles.repoSelectStyles(true, colors)}
        >
          {IMAGE_REPO_SUFFIX.map((suff) => (
            <MenuItem
              value={`${IMAGE_REPO_PREFIX[artifactType]}${suff}`}
              key={suff}
            >{`${IMAGE_REPO_PREFIX[artifactType]}${suff}`}</MenuItem>
          ))}
        </Select>
        <TextField
          autoFocus
          margin="dense"
          inputProps={{
            style: refreshDialogStyles.tagTextFieldInputStyle(true, colors),
          }}
          variant="outlined"
          size="small"
          sx={refreshDialogStyles.textFieldStyle("170px")}
          disabled={isConfirmationDialog}
          value={additionalReleaseManifest[artifactType][idx]?.name || ""}
          onChange={(e) => handleFieldOnChange(e, "name")}
        />
        <TextField
          autoFocus
          margin="dense"
          inputProps={{
            style: refreshDialogStyles.tagTextFieldInputStyle(true, colors),
          }}
          variant="outlined"
          size="small"
          sx={refreshDialogStyles.tagTextFieldStyle("80px")}
          disabled={isConfirmationDialog}
          value={additionalReleaseManifest[artifactType][idx]?.tag || ""}
          onChange={(e) => handleFieldOnChange(e, "tag")}
        />
        <IconButton
          sx={refreshDialogStyles.cancelCustomKeyButtonStyle}
          onClick={() => {
            setAdditionalReleaseManifest((prev) => ({
              ...prev,
              [artifactType]: additionalReleaseManifest[artifactType].filter(
                (_, i) => idx !== i
              ),
            }));
          }}
        >
          <Cancel />
        </IconButton>
      </Box>
      <Divider />
    </>
  );
};

export default AdditionalServiceItem;
