import { colors as muiColors } from "@mui/material";
import { PR_BUILD_STATUS } from "../../utils/constants";

const styles = {
  // Main
  viewSelectForm: {
    width: "25%",
    mt: "5px",
    ml: "2px",
    paddingX: "5px",
  },
  viewSelectSelect: {
    height: "35px",
  },
  loadingBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "75vh",
  },

  // ---------
  // Release view
  releaseViewStyle: { mt: "-20px" },
  multiSelectStack: {
    display: "flex",
    flexDirection: "row",
  },
  multiSelect: { m: 1, minWidth: "15%" },

  releaseHeading: {
    fontWeight: 700,
    p: "5px",
  },
  boldText: {
    fontWeight: 700,
  },
  inlineBoldText: {
    fontWeight: 700,
    display: "inline",
  },
  inlineStyle: {
    display: "inline",
  },
  noData: { p: "20px" },

  // ---------
  // Summary view
  summaryViewStyle: { p: "10px" },

  cardSummaryItemStyle: {
    margin: "5px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    paddingY: "7px",
  },

  stageNameStyle: { minWidth: "70px" },
  releaseCardHeadingStack: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    pb: "10px",
  },
  stageStatusStyle: (colors, getProperties, status) => ({
    borderRadius: 1,
    bgcolor: getProperties()[status]?.color || colors.primary[700],
    padding: "5px",
  }),

  // ---------
  // Patch Label Dialog
  scheduledPatchStack: {
    display: "flex",
    flexDirection: "row-reverse",
    mb: "5px",
    mt: "-3px",
  },
  repoAccordionStyle: (colors) => ({
    backgroundColor: colors.primary[700],
    width: "1000px",
  }),
  commitListItemStack: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1rem",
  },
  buildStatus: (buildStatus) => {
    const colorMap = {
      [PR_BUILD_STATUS[0]]: muiColors.yellow[800],
      [PR_BUILD_STATUS[1]]: muiColors.green[700],
      [PR_BUILD_STATUS[2]]: muiColors.red[800],
    };
    return {
      borderRadius: 1,
      bgcolor: colorMap?.[buildStatus],
      padding: "3px",
    };
  },
};
export default styles;
