import { colors as muiColors } from "@mui/material";

const styles = {
  labelSelectSelect: {
    minWidth: "130px",
    width: "25%",
    m: "5px 0 0 2px",
    height: "35px",
  },
  selectLabelFrom: {
    minWidth: "130px",
    height: "35px",
  },
  loadingBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "75vh",
  },

  appHealthGridStyles: { px: "10px" },

  // ----------------
  // AppHealthCard
  appCardHeadingStack: {
    display: "flex",
    flexDirection: "row",
    gap: "1rem",
    pb: "10px",
  },
  cardSummaryItemStyle: {
    display: "flex",
    ml: "10px",
    justifyContent: "left",
    alignItems: "left",
    gap: "10px",
    paddingY: "7px",
  },
  cardLabelBoxStyle: {
    display: "flex",
    flexDirection: "column",
    width: "125px",
    ml: "-10px",
  },
  appIconStyle: {
    mt: "20px",
  },
  cardDetailsBoxStyle: {
    display: "flex",
    flexDirection: "column",
  },

  resultStack: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    ml: "10px",
    mt: "5px",
  },
  resultStack2: {
    gap: 2,
    ml: "30px",
    mt: "15px",
  },
  resultIconsStyle: {
    Passed: { color: muiColors.green[500] },
    Skipped: { color: muiColors.yellow[800] },
    Failed: { color: muiColors.red[500] },
  },
};
export default styles;
