/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import ConvoyDialog from "../../components/ConvoyDialog";
import { isValidJSON } from "../../utils/helperFunctions";
import { useTriggerAutomationRun } from "../../api/automationRuns";
import { useGetTenant } from "../../api/envs";
import { styles } from "./styles";
import { triggerAutomationPayload } from "./payloadTemplate";

const TriggerAutomationDialog = ({
  dialog,
  setDialog,
  deploymentStage,
  tenantUrl,
  showNotificationWrapper,
  callback,
}) => {
  const [configJSON, setConfigJSON] = useState(triggerAutomationPayload);
  const resetDialog = () => {
    setConfigJSON(triggerAutomationPayload);
    setDialog({ isOpen: false });
  };

  const {
    postData: triggerAutomation,
    response: triggerAutomationResp,
    responseStatus: triggerAutomationRespStatus,
  } = useTriggerAutomationRun(deploymentStage, tenantUrl);

  const {
    postData: getTenant,
    response: getTenantResp,
    isLoading: isLoadingGetTenant,
  } = useGetTenant(tenantUrl);

  useEffect(() => {
    if (dialog?.isOpen) {
      getTenant();
    }
  }, [dialog]);

  useEffect(() => {
    showNotificationWrapper(
      triggerAutomationResp,
      triggerAutomationRespStatus,
      callback
    );
  }, [triggerAutomationResp, triggerAutomationRespStatus]);

  const handleDialogConfirm = () => {
    triggerAutomation(JSON.parse(configJSON));
    resetDialog();
  };

  return (
    <ConvoyDialog
      open={dialog?.isOpen}
      title="Trigger Automation"
      handleDialogConfirm={handleDialogConfirm}
      resetDialog={resetDialog}
      disabled={!isValidJSON(configJSON)}
    >
      {isLoadingGetTenant ? (
        <Stack>
          <CircularProgress color="inherit" sx={styles.triggerDialogLoading} />
        </Stack>
      ) : ["RUNNING", "UPDATE_FAILED"].includes(getTenantResp?.status) ? (
        <>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography component="span" variant="h5">
              Trigger automation tests for {tenantUrl?.split(".")?.[0]}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={styles.textFieldStack}
          >
            <TextField
              fullWidth
              id="outlined-multiline-flexible"
              label="Payload"
              multiline
              maxRows={25}
              value={configJSON}
              onChange={(e) => {
                setConfigJSON(e.target.value);
              }}
            />
          </Stack>
        </>
      ) : (
        <Typography component="span" variant="h5">
          Cannot trigger automation because {tenantUrl?.split(".")?.[0]} is in{" "}
          {getTenantResp?.status} state
        </Typography>
      )}
    </ConvoyDialog>
  );
};

const useTriggerAutomationDialog = (
  deploymentStage,
  tenantUrl,
  showNotificationWrapper,
  callback
) => {
  const [dialog, setDialog] = useState({ isOpen: false });
  return {
    triggerAutomationDialog: (
      <TriggerAutomationDialog
        dialog={dialog}
        setDialog={setDialog}
        deploymentStage={deploymentStage}
        tenantUrl={tenantUrl}
        showNotificationWrapper={showNotificationWrapper}
        callback={callback}
      />
    ),
    setTriggerAutomationDialogOpen: () => setDialog({ isOpen: true }),
  };
};

export default useTriggerAutomationDialog;
