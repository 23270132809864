import { FormControl, InputLabel, Select as MuiSelect } from "@mui/material";

const Select = (props) => {
  const { children, fullWidth, ...rest } = props;
  return (
    <FormControl fullWidth={fullWidth}>
      <InputLabel id="lId" color={rest?.color}>
        {rest?.label}
      </InputLabel>
      <MuiSelect labelId="lId" {...rest}>
        {children}
      </MuiSelect>
    </FormControl>
  );
};
export default Select;
