import { githubUrl } from "../utils/urls";

export const adaptReleases = (releases) => {
  const parseReleaseName = (release) => {
    return (
      release?.release_label &&
      `Release ${release?.release_label?.split(".")[1]}`
    );
  };

  return releases?.map((release) => {
    const labels = release?.patches || release?.releases;
    return {
      name: parseReleaseName(release) || release?.repo_name,
      labels: labels?.map((patches) => {
        const commitGroups = patches?.repositories || patches?.patches;
        return {
          name: patches?.patch_label || parseReleaseName(patches),
          commitGroups: commitGroups?.map((repo) => {
            return {
              name: repo?.repo_name || repo?.patch_label,
              commits: repo?.commits?.map((commit) => {
                const repoName = release?.repo_name || repo?.repo_name;
                return {
                  ...commit,
                  commit_url: `${githubUrl}/${repoName}/commit/${commit?.commit_hash}`,
                };
              }),
            };
          }),
        };
      }),
    };
  });
};

// ---Release INPUT---
// releases:
// - release_label: ''
//   patches:
//   - patch_label: ''
//     repositories:
//     - repo_name: ''
//       commits: []
//
// --Service INPUT--
// repositories:
// - repo_name: ''
//   releases:
//   - release_label: ''
//     patches:
//     - patch_label: ''
//       commits: []

// ---OUTPUT---
// labelTypes:
// - name: ''
//   labels:
//   - name: ''
//     commitGroups:
//     - name: ''
//       commits: []
