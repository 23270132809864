/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from "react";
import Box from "@mui/material/Box";
import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import AutomationRuns from "./AutomationRuns";
import { tabbedDataGridPage } from "../../styles/commonPageStyles";

const Automations = () => {
  const [selectedTab, setSelectedTab] = useState("automationRuns");

  return (
    <Box sx={tabbedDataGridPage.mainBox}>
      <Box sx={tabbedDataGridPage.tabContainer}>
        <TabContext value={selectedTab}>
          <Box sx={tabbedDataGridPage.tabListBox}>
            <TabList
              onChange={(e, newValue) => setSelectedTab(newValue)}
              textColor="secondary"
              indicatorColor="secondary"
              sx={tabbedDataGridPage.tabList}
            >
              <Tab
                key="main"
                label="Automation Runs"
                value="automationRuns"
                icon={<></>}
                iconPosition="end"
              />
            </TabList>
          </Box>

          <TabPanel key="main" value="automationRuns">
            <AutomationRuns />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default Automations;
