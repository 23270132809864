import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import RedirectLink from "../../../components/RedirectLink";
import { shadowBox } from "../../../styles/cardStyles";
import styles from "../styles";

const CommitGroupBox = ({ commitGroup = [] }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box sx={shadowBox(colors)}>
      <Typography variant="h5" sx={styles.boldText}>
        {commitGroup?.name}
      </Typography>
      <ul>
        {commitGroup?.commits?.map((commit, i) => {
          return (
            <li key={i}>
              <Typography variant="h5" sx={styles.inlineBoldText}>
                {commit?.commit_tag} |&nbsp;&nbsp;
              </Typography>
              <RedirectLink href={commit?.commit_url}>
                {commit?.commit_message}
              </RedirectLink>
            </li>
          );
        })}
      </ul>
    </Box>
  );
};

export default CommitGroupBox;
