import ConvoyDialog from "./ConvoyDialog";
import { Typography } from "@mui/material";

const ConfirmationDialog = ({
  isOpen,
  handleDialogConfirm,
  title,
  content,
  resetDialog,
}) => {
  if (!isOpen) {
    return <></>;
  }
  return (
    <ConvoyDialog
      open={isOpen}
      title={title || "Confirmation"}
      handleDialogConfirm={() => {
        handleDialogConfirm();
        resetDialog();
      }}
      resetDialog={resetDialog}
    >
      <Typography component="span" variant="h5">
        {content || ""}
      </Typography>
    </ConvoyDialog>
  );
};

export default ConfirmationDialog;
