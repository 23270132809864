export const timeFormat = "D MMM YYYY, h:mm:ss a";

// --------------------
export const USER_ROLES = {
  CODE_VULNERABILITY_REVIEWERS: "CODE_VULNERABILITY_REVIEWERS",
  DEVOPS_TEAM: "DEVOPS_TEAM",
  INFRA_TEAM: "INFRA_TEAM",
  RELEASE_OWNER: "RELEASE_OWNER",
  QA_AUTOMATION_TEAM: "QA_AUTOMATION_TEAM",
};

export const INFINITE_SCROLLING_PAGE_SIZE = 20;

export const APP_GROUPS = {
  TESSELL: "tessell",
};
// --------------------
// Environments Page
export const HIBERNATE_WAKEUP_ENABLED_STATUS = [
  "RUNNING",
  "HIBERNATED",
  "HIBERNATE_FAILED",
  "WAKEUP_FAILED",
  "UPDATE_FAILED",
  "DELETE_FAILED",
];

// refresh
export const IMAGE_REPO_PREFIX = {
  dockerImages: "tessell",
  helmCharts: "nexus-",
};
export const IMAGE_REPO_SUFFIX = ["prod", "dev"];

export const RMO_ARTIFACTS_ENUM = "artifacts";

export const RMO_IMAGE_COMPONENTS = ["repository", "name", "tag"];

export const NEXUS_REPO = {
  dev: "tessell-repos-m2-development",
  prod: "tessell-repos-m2-component",
  devPullRepo: "tessell-m2-development",
};

export const TENANT_UI = { rmoKey: "tenant-ui", artifact: "tessell-ui" };

// Control plane requests
export const CONTROL_PLANE_SERVICE_PLAN = [
  "VIRTUAL_PRIVATE_TESSELL_AT_CUSTOMER",
  "VIRTUAL_PRIVATE_TESSELL",
  "BUSINESS",
];

export const CONTROL_PLANE_CLOUD_TYPE = ["AWS", "AZURE"];

export const GLOBAL_ENVIRONMENT_NAME = ["TERLS", "BARC"];

// BYOA requests
export const DATA_PLANE_CLOUD_TYPE = ["AWS", "AZURE"];

// --------------------
// Releases Page
export const PR_BUILD_STATUS = ["IN_PROGRESS", "SUCCESSFUL", "FAILED"];
export const DISABLED_PATCH_RELEASE_STATUS = ["REJECTED", "ARCHIVED"];
export const SCHEDULED_PATCH_THRESHOLD_TIME = "04-30-00";

// --------------------
// Requests Page
export const REQUEST_STATUS = {
  PENDING: "PENDING",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const REQUEST_VIEWS = {
  requestsMade: "Requests Made",
  requestsReceived: "Requests Received",
};

// --------------------
// UiProxy Page
export const UI_PROXY_CONTEXT = {
  logs: "logs",
};

// --------------------
// Vulnerabilities Page
export const VULNERABILITY_STATUS = {
  NEW: "NEW",
  PENDING_VALIDATION: "PENDING_VALIDATION",
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};
