import { Checkbox, Stack, Typography } from "@mui/material";

const WakeupDialogContent = ({ env, setRefreshDialog, setDialog }) => {
  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography component="span" variant="h5">
          You are going to wake up the environment {env?.name}
        </Typography>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Typography component="span" variant="h5">
          Auto Refresh on wake up
        </Typography>
        <Checkbox
          color="secondary"
          size="small"
          onChange={(e) => {
            setRefreshDialog({
              selectedEnv: env,
              isOpen: true,
            });
            setDialog({});
          }}
        />
      </Stack>
    </>
  );
};

export default WakeupDialogContent;
