import { Typography, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import { tokens } from "../theme";

const styles = {
  mainPaperProps: (colors) => ({
    elevation: 0,
    style: {
      backgroundColor: colors.primary[700],
      boxShadow: "10px 10px 100px 35px black",
    },
  }),
};

const ConvoyDialog = ({
  open,
  title,
  handleDialogConfirm,
  confirmBtnText,
  disabled,
  onCancelClick,
  cancelBtnText,
  resetDialog,
  children,
  additionalActions,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (!open) {
    return <></>;
  }

  return (
    <Dialog
      open={open}
      onClose={resetDialog}
      maxWidth="lg"
      PaperProps={styles.mainPaperProps(colors)}
    >
      <DialogTitle component="span">
        <Typography variant="h4">{title}</Typography>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onCancelClick || resetDialog}>
          <Typography variant="h6" color={colors.grey[100]} fontWeight={600}>
            {cancelBtnText || "Cancel"}
          </Typography>
        </Button>
        {additionalActions?.length &&
          additionalActions.map((action) => {
            return (
              <Button onClick={action?.onClick}>
                <Typography
                  variant="h6"
                  color={colors.grey[100]}
                  fontWeight={600}
                >
                  {action?.buttonText}
                </Typography>
              </Button>
            );
          })}
        <Button onClick={handleDialogConfirm} disabled={disabled} autoFocus>
          <Typography
            variant="h6"
            color={disabled ? colors.grey[500] : colors.grey[100]}
            fontWeight={600}
          >
            {confirmBtnText || "Confirm"}
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConvoyDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  handleDialogConfirm: PropTypes.func,
  resetDialog: PropTypes.func.isRequired,
  confirmBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
};

export default ConvoyDialog;
