/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { tokens } from "../../../theme";
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "../../../components/Header";
import DataGridButton from "../../../components/DataGridButton";
import AppHealthCard from "./AppHealthCard";
import { useGetAvailableLabels, useGetLabelHealth } from "../../../api/qa";
import styles from "./styles";
import { dataGridButtonTypography } from "../../../styles/DataGridStyles";
import { dataGridButtonStyles } from "../../../styles/buttonStyles";
import { tabbedDataGridPage } from "../../../styles/commonPageStyles";
import { jiraMainLabelDashbord, qaosUrl } from "../../../utils/urls";
import { useReportModal } from "../../../components/ReportModal";

const LabelHealthDashboard = ({}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [availableLabels, setAvailableLabels] = useState([]);
  const [selectedLabel, setSelectedLabel] = useState("");
  const [runResults, setRunResults] = useState({});

  const { postData: getAvailableLabels, response: getAvailableLabelsResp } =
    useGetAvailableLabels();

  const { reportModal, setReportModalOpen } = useReportModal();

  const {
    postData: getLabelHealth,
    response: getLabelHealthResp,
    isLoading: isLoadingLabelHealth,
  } = useGetLabelHealth(selectedLabel);

  useEffect(() => {
    getAvailableLabels();
  }, []);
  useEffect(() => {
    if (Array.isArray(getAvailableLabelsResp?.releaseLabels)) {
      setAvailableLabels(getAvailableLabelsResp?.releaseLabels);
    }
  }, [getAvailableLabelsResp]);

  useEffect(() => {
    if (!selectedLabel && availableLabels?.length > 0) {
      setSelectedLabel(availableLabels[0]);
    }
  }, [availableLabels]);

  useEffect(() => {
    if (selectedLabel) {
      getLabelHealth();
    }
  }, [selectedLabel]);

  useEffect(() => {
    setRunResults(getLabelHealthResp?.resultMetadata?.results || {});
  }, [getLabelHealthResp]);

  return (
    <Box sx={tabbedDataGridPage.tabContentMainBox}>
      {reportModal}
      <Grid sx={tabbedDataGridPage.gridContainer} container columns={2}>
        <Grid item xs={1}>
          <Header
            title="Label Health"
            subtitle={`View label health for main branch`}
          />
        </Grid>
        <Grid item xs={1}>
          <Stack direction={"row-reverse"}>
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              onClick={() => getLabelHealth()}
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                RELOAD
              </Typography>
            </DataGridButton>
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              onClick={() => window.open(jiraMainLabelDashbord, "_blank")}
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                JIRAs
              </Typography>
            </DataGridButton>
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              onClick={() =>
                setReportModalOpen(
                  `${qaosUrl}/tessell/dev/label-health/release-labels/${selectedLabel}/report`
                )
              }
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                REPORT
              </Typography>
            </DataGridButton>
            <FormControl sx={styles.selectLabelFrom}>
              <InputLabel id="select-label">Label</InputLabel>
              <Select
                labelId="select-label-label"
                id="select-label"
                value={selectedLabel || " "}
                sx={styles.labelSelectSelect}
                label="Label"
                onChange={(e) => setSelectedLabel(e.target.value)}
              >
                {availableLabels?.map((appGroup, i) => (
                  <MenuItem value={appGroup} key={i}>
                    {appGroup}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Grid>
      </Grid>

      <Box>
        <Grid container spacing={2} sx={styles.appHealthGridStyles}>
          {isLoadingLabelHealth ? (
            <Box sx={styles.loadingBox}>
              <CircularProgress color="secondary" />
            </Box>
          ) : (
            Object.keys(runResults)?.map((appName, i) => {
              return (
                <Grid item xs={6} key={i}>
                  <AppHealthCard
                    appName={appName}
                    testResult={runResults[appName]}
                  />
                </Grid>
              );
            })
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default LabelHealthDashboard;
