// case transform --------
export const splitWords = (str) =>
  (str.toUpperCase() === str ? str : str.replace(/([A-Z])/g, " $1"))
    .replace(/(_)/g, " ")
    .split(" ")
    .filter(Boolean);

export const toTitleCase = (str) =>
  splitWords(str)
    .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
    .join(" ");

export const toPascalCase = (str) => toTitleCase(str).replaceAll(" ", "");

export const toCamelCase = (str) =>
  str.charAt(0).toLowerCase() + toPascalCase(str).slice(1);

export const toSnakeCase = (str) => splitWords(str).join("_").toLowerCase();

export const toKebabCase = (str) => splitWords(str).join("-").toLowerCase();

// ---------------------

export const isValidJSON = (jsonString) => {
  try {
    const obj = JSON.parse(jsonString);
    if (obj && typeof obj === "object") {
      return true;
    }
  } catch (e) {}
  return false;
};

export const removeDuplicates = (arr) => {
  return [...new Set(arr)];
};

// --------------------
export const extractMessageFromResponse = (resp) =>
  resp?.message || resp?.detail?.message || resp?.detail?.msg || resp?.detail;

export const showNotification = (
  resp,
  respStatus,
  successCallback,
  setMessage,
  setSeverity,
  setNotify
) => {
  const message = extractMessageFromResponse(resp);
  if (message?.length && typeof respStatus === "number") {
    setMessage(message);
    setSeverity(respStatus < 300 ? "success" : "error");
    setNotify(true);
    if (respStatus < 300) {
      successCallback();
    }
  }
};

export const downloadFileFromUrl = (url) => {
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
