import { useContext, useEffect } from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import { UserContext } from "../../../contexts";
import Select from "../../../components/Select";
import { toCamelCase } from "../../../utils/helperFunctions";
import { DATA_PLANE_CLOUD_TYPE } from "../../../utils/constants";
import { requestCPDialogStyles } from "../styles";

const dataPlaneCLoudType = [DATA_PLANE_CLOUD_TYPE[0]];

const RequestBYOADialogContent = ({
  env,
  requestBYOAPayload,
  setRequestBYOAPayload,
}) => {
  const loggedInUser = useContext(UserContext);
  useEffect(() => {
    setRequestBYOAPayload({
      approver: loggedInUser?.data?.quotas?.controlPlane?.allocated
        ? loggedInUser?.data?.email
        : loggedInUser?.data?.manager?.email,
      subscriptionName: "",
      cloudType: "",
      tenantUrl: env?.url,
    });
  }, []);

  const textFieldCommonProps = (label) => ({
    fullWidth: true,
    label: label,
    color: "secondary",
    value: requestBYOAPayload?.[toCamelCase(label)],
    onChange: (e) => {
      setRequestBYOAPayload((prev) => ({
        ...prev,
        [toCamelCase(label)]: e.target.value,
      }));
    },
  });

  return (
    <>
      <Grid container spacing={2} sx={requestCPDialogStyles.gridContainer}>
        <Grid item xs={5}>
          <TextField {...textFieldCommonProps("Subscription Name")} />
        </Grid>
        <Grid item xs={3}>
          <Select {...textFieldCommonProps("Cloud Type")}>
            {dataPlaneCLoudType.map((cloud, i) => (
              <MenuItem value={cloud} key={i}>
                {cloud}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <TextField
            {...textFieldCommonProps("Manager")}
            defaultValue={loggedInUser?.data?.manager?.email}
            disabled
          />
        </Grid>
      </Grid>
    </>
  );
};

export default RequestBYOADialogContent;
