import { Box, Divider, IconButton, Typography, useTheme } from "@mui/material";
import { refreshDialogStyles } from "../../../styles";
import { tokens } from "../../../../../theme";
import { stackCenterGap } from "../../../../../styles/utilStyles";

import { detectChange } from "../helperFunctions";
import { CancelOutlined, East } from "@mui/icons-material";
import { RMO_IMAGE_COMPONENTS } from "../../../../../utils/constants";

const ConfirmationServiceItem = ({
  idx,
  originalReleaseManifest,
  updatedReleaseManifest,
  setUpdatedReleaseManifest,
  service,
  artifactType,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const discardItem = (item) => {
    setUpdatedReleaseManifest((prev) => {
      const curr = structuredClone(prev);
      curr[artifactType][service][item] =
        originalReleaseManifest[artifactType][service][item];
      return curr;
    });
  };
  return (
    <>
      {idx ? <Divider /> : <></>}
      <Box sx={refreshDialogStyles.serviceItemStack}>
        <Typography
          variant="h5"
          sx={refreshDialogStyles.confirmationServiceTypography}
        >
          {service}
        </Typography>
        <Box sx={stackCenterGap} />
        <Box sx={refreshDialogStyles.diffBox}>
          {RMO_IMAGE_COMPONENTS.map(
            (item) =>
              detectChange(
                item,
                originalReleaseManifest,
                updatedReleaseManifest,
                artifactType,
                service
              ) && (
                <Box sx={refreshDialogStyles.diffRow}>
                  <Typography variant="h5" sx={refreshDialogStyles.diffItemKey}>
                    {item}
                  </Typography>
                  <Typography
                    variant="h5"
                    sx={refreshDialogStyles.diffItem(colors.red)}
                  >
                    {originalReleaseManifest[artifactType][service]?.[item]}
                  </Typography>
                  <East sx={refreshDialogStyles.arrowStyle} />
                  <Typography
                    variant="h5"
                    sx={refreshDialogStyles.diffItem(colors.green)}
                  >
                    {updatedReleaseManifest[artifactType][service]?.[item]}
                  </Typography>
                  <IconButton onClick={() => discardItem(item)}>
                    <CancelOutlined />
                  </IconButton>
                </Box>
              )
          )}
        </Box>
      </Box>
    </>
  );
};

export default ConfirmationServiceItem;
