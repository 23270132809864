import { colors as muiColors } from "@mui/material";
import { commonDataGridStyles } from "../../styles/DataGridStyles";

export const packageVulnerabilityStyles = {
  dataGridBoxStyles: (colors) => ({
    ...commonDataGridStyles(colors),
    height: "80vh",
    mt: "20px",
    width: "99.4%",
  }),
  multiSelectStack: {
    display: "flex",
    flexDirection: "row",
  },
  multiSelect: {
    sx: {
      mt: "-25px",
      ml: "2px",
      paddingX: "5px",
      maxHeight: "10px",
      minWidth: "10%",
    },
    chipSx: { height: "19px" },
    textFieldProps: { size: "small" },
  },
  fixAvailableIconStyles: (fixAvailable) => ({
    color: fixAvailable ? muiColors.green[500] : muiColors.red[500],
  }),
};

export const detailPanelContentSyles = {
  gridContainerSyles: { py: 2, height: "100%", boxSizing: "border-box" },
  paperStyles: (colors) => ({
    flex: 1,
    mx: "auto",
    width: "99%",
    p: 1,
    height: "100%",
    border: `1px solid ${colors.grey[400]}`,
  }),
  metadataTextFieldInputProps: {
    readOnly: true,
    style: { fontSize: 12, fontFamily: "Courier" },
    disableUnderline: true,
  },
  metadataTextFieldSx: { m: "5px", width: "100%" },
  textBlockHeadingTypoStyle: { mt: "15px", fontWeight: "700" },
};
