import { useState } from "react";
import { TextField } from "@mui/material";

export const TextFieldWithHelperText = (props) => {
  const [focused, setFocused] = useState(false);

  return (
    <TextField
      {...props}
      onFocus={() => setFocused(true) && props.onFocus()}
      onBlur={() => setFocused(false) && props.onBlur()}
      helperText={focused && props?.helperText}
    />
  );
};
