/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { Box, Tooltip } from "@mui/material";
import { commonButtonStyles } from "../styles/buttonStyles";

const styles = (colors, m, width, hue) => ({
  width: width || "40%",
  borderRadius: "4px",
  ...commonButtonStyles(colors, m, hue),
});

const wrapperBox = (width, m) => ({
  width: width || "40%",
  m: m || "0 auto",
});

const DataGridButton = ({
  onClick,
  disabled,
  children,
  sx,
  hue,
  toolTipProps,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { width, m, ...sX } = sx || {};
  return (
    <Tooltip {...(toolTipProps || {})} size="lg">
      <Box sx={wrapperBox(width, m)}>
        <Box
          component="button"
          sx={{ ...styles(colors, 0, "100%", hue), ...sX }}
          disabled={disabled}
          onClick={onClick}
        >
          {children}
        </Box>
      </Box>
    </Tooltip>
  );
};

export default DataGridButton;
