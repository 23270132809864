/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import {
  Paper,
  Box,
  Stack,
  Typography,
  useTheme,
  Tooltip,
  Switch,
} from "@mui/material";
import DataGridButton from "../../components/DataGridButton";
import DropDownButton from "../../components/DropDownButton";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import { ContentCopy } from "@mui/icons-material";
import { dataGridButtonTypography } from "../../styles/DataGridStyles";
import {
  tenantInstancesUrl,
  patchEnv,
  useGetEnvs,
  useGetUsers,
  useRequestCP,
  useHibernateEnv,
  useWakeUpEnv,
  useDeleteEnv,
  useRefreshEnv,
  useConvertToVPTC,
  useRequestBYOA,
  useAddLicences,
} from "../../api/envs";
import Notification from "../../components/Notification";
import ConvoyDialog from "../../components/ConvoyDialog";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { CustomEnvFooter, statusEnum } from "./CustomEnvFooter";
import MembersList from "./MembersList";
import ScheduledConfigs from "./ScheduledConfigs";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import RefreshDialog from "./Dialog/RefreshDialog";
import BYOASubscriptionsModal from "./Dialog/BYOASubscriptionsModal";
import RequestCPDialogContent from "./Dialog/RequestCPDialogContent";
import RequestBYOADialogContent from "./Dialog/RequestBYOADialogContent";
import WakeupDialogContent from "./Dialog/WakeUpDialogContent";
import {
  isValidRequestCPPayload,
  roles,
  notifyMinOptions,
  parseRequestCPPayload,
  getEnvsSimplified,
  getEnvStatusCount,
} from "./utils";
import { red } from "@mui/material/colors";
import { HIBERNATE_WAKEUP_ENABLED_STATUS } from "../../utils/constants";
import { indexStyles } from "./styles";
import { dataGridButtonStyles } from "../../styles/buttonStyles";
import { convertLocalTimeToUTCTime } from "../../utils/dateUtils";

const Environments = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  const [envs, setEnvs] = useState([]);
  const [updates, setUpdates] = useState({});
  const [updatesIsEmpty, setUpdatesIsEmpty] = useState(true);
  const [idToIndexMap, setIdToIndexMap] = useState({});
  const [envsWithUpdates, setEnvsWithUpdates] = useState([]);
  const [isSavingUpdates, setIsSavingUpdates] = useState(false);
  const [statusCounts, setStatusCounts] = useState({});

  const [toShowAllEnvs, setToShowAllEnvs] = useState(false);

  const { postData: getUsers, response: getUsersResp } = useGetUsers();

  const {
    postData: getEnvs,
    response: getEnvsResp,
    isLoading: isLoadingEnvs,
  } = useGetEnvs();

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    getEnvs(showAllEnvs(toShowAllEnvs));
  }, [toShowAllEnvs]);

  useEffect(() => {
    if (Array.isArray(getUsersResp) && Array.isArray(getEnvsResp?.response)) {
      const envsSimplified = getEnvsSimplified(
        getEnvsResp.response,
        getUsersResp
      );

      setStatusCounts(getEnvStatusCount(envsSimplified, statusEnum));
      setEnvs(structuredClone(envsSimplified));
      setUpdatesIsEmpty(checkIfUpdatesIsEmpty());
      setEnvsWithUpdates(mergeEnvsWithUpdates({ passedEnvs: envsSimplified }));

      const newMap = {};
      envsSimplified.forEach((env, index) => (newMap[env["id"]] = index));
      setIdToIndexMap(newMap);
    }
  }, [getEnvsResp, getUsersResp]);

  const mergeEnvsWithUpdates = ({ passedUpdates, passedEnvs } = {}) => {
    const localEnvs = [...(passedEnvs || envs)];

    const updatesToUse = passedUpdates !== undefined ? passedUpdates : updates;
    Object.keys(updatesToUse).forEach((envId) => {
      const updatesInEnv = updatesToUse[envId];
      const idx = idToIndexMap[envId];
      const env = { ...localEnvs[idx] };

      if ("autoHibernateEnabled" in updatesInEnv) {
        env.autoHibernateEnabled = updatesInEnv?.autoHibernateEnabled;
      }
      if ("autoHibernateSchedule" in updatesInEnv) {
        env.autoHibernateSchedule = updatesInEnv?.autoHibernateSchedule;
      }
      if ("autoHibernateRepeat" in updatesInEnv) {
        env.autoHibernateRepeat = updatesInEnv?.autoHibernateRepeat;
      }
      if ("autoHibernateNotify" in updatesInEnv) {
        env.autoHibernateNotify = updatesInEnv?.autoHibernateNotify;
      }
      if ("autoWakeupEnabled" in updatesInEnv) {
        env.autoWakeupEnabled = updatesInEnv?.autoWakeupEnabled;
      }
      if ("autoWakeupSchedule" in updatesInEnv) {
        env.autoWakeupSchedule = updatesInEnv?.autoWakeupSchedule;
      }
      if ("autoWakeupRepeat" in updatesInEnv) {
        env.autoWakeupRepeat = updatesInEnv?.autoWakeupRepeat;
      }
      if ("autoWakeupNotify" in updatesInEnv) {
        env.autoWakeupNotify = updatesInEnv?.autoWakeupNotify;
      }

      let members = { ...env?.members };
      let indexedMembers = [...env?.indexedMembers];
      if ("membersAdded" in updatesInEnv) {
        updatesInEnv?.addedMembersIndex?.forEach((email) => {
          members[email] = updatesInEnv?.membersAdded.get(email);
          if (!indexedMembers.includes(email)) {
            indexedMembers.push(email);
          }
        });
      }
      if ("membersDeleted" in updatesInEnv) {
        updatesInEnv?.membersDeleted?.forEach((email) => {
          delete members[email];
        });
      }
      if ("membersModified" in updatesInEnv) {
        updatesInEnv?.membersModified?.forEach((role, email) => {
          members[email] = role;
        });
      }
      env.members = members;
      env.indexedMembers = indexedMembers;
      localEnvs[idx] = env;
    });
    return localEnvs;
  };

  const checkIfUpdatesIsEmpty = (passedUpdates) => {
    const updatesToUse = passedUpdates !== undefined ? passedUpdates : updates;
    let empty = true;
    Object.keys(updatesToUse).forEach((envId) => {
      const updatesInEnv = updatesToUse[envId];
      updatesInEnv.hasUpdates = false;
      updatesInEnv.hasUpdatesInMembers = false;
      updatesInEnv.hasConfigUpdates = false;
      if (
        updatesInEnv?.membersAdded?.size ||
        updatesInEnv?.membersDeleted?.size ||
        updatesInEnv?.membersModified?.size
      ) {
        updatesInEnv.hasUpdatesInMembers = true;
      }
      if (
        "autoHibernateEnabled" in updatesInEnv ||
        "autoHibernateSchedule" in updatesInEnv ||
        "autoHibernateRepeat" in updatesInEnv ||
        "autoHibernateNotify" in updatesInEnv ||
        "autoWakeupEnabled" in updatesInEnv ||
        "autoWakeupSchedule" in updatesInEnv ||
        "autoWakeupRepeat" in updatesInEnv ||
        "autoWakeupNotify" in updatesInEnv
      ) {
        updatesInEnv.hasConfigUpdates = true;
      }
      if (updatesInEnv?.hasUpdatesInMembers || updatesInEnv?.hasConfigUpdates) {
        updatesInEnv.hasUpdates = true;
        empty = false;
      }
    });
    return empty;
  };

  const [notify, setNotify] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const [dialog, setDialog] = useState({
    selectedEnv: {},
    context: "",
    title: "",
    isOpen: false,
    content: <></>,
  });

  const [refreshDialog, setRefreshDialog] = useState({
    selectedEnv: {},
    isOpen: false,
  });

  const [byoaModal, setByoaModal] = useState({
    selectedEnv: {},
    isOpen: false,
  });

  const [confirmationDialog, setConfirmationDialog] = useState({
    isOpen: false,
    title: "",
    content: "",
    handleDialogConfirm: () => {},
  });

  const [configJSON, setConfigJSON] = useState("");
  const [requestCPPayload, setRequestCPPayload] = useState({});
  const [requestBYOAPayload, setRequestBYOAPayload] = useState({});

  const resetDialog = () => {
    setDialog({
      selectedEnv: {},
      context: "",
      title: "",
      isOpen: false,
      content: <></>,
    });
    setConfirmationDialog({
      isOpen: false,
      title: "",
      content: "",
      handleDialogConfirm: () => {},
    });
    setConfigJSON("");
  };

  const {
    postData: requestCP,
    response: requestCPResp,
    responseStatus: requestCPRespStatus,
  } = useRequestCP();

  const {
    postData: hibernate,
    response: hibResp,
    responseStatus: hibRespStatus,
  } = useHibernateEnv();

  const {
    postData: wakeup,
    response: wakeupResp,
    responseStatus: wakeupRespStatus,
  } = useWakeUpEnv();

  const {
    postData: refreshEnv,
    response: refreshEnvResp,
    responseStatus: refreshEnvRespStatus,
  } = useRefreshEnv();

  const {
    postData: convertToVPTC,
    response: convertToVPTCResp,
    responseStatus: convertToVPTCRespStatus,
  } = useConvertToVPTC();

  const {
    postData: requestBYOA,
    response: requestBYOAResp,
    responseStatus: requestBYOARespStatus,
  } = useRequestBYOA();

  const {
    postData: addLicences,
    response: addLicencesResp,
    responseStatus: addLicencesRespStatus,
  } = useAddLicences();

  const {
    postData: deleteEnv,
    response: deleteEnvResp,
    responseStatus: deleteEnvRespStatus,
  } = useDeleteEnv();

  const handleDialogConfirm = () => {
    const url = dialog.selectedEnv?.url;
    switch (dialog.context) {
      case "wakeUp":
        wakeup({ url });
        break;
      case "requestCP":
        requestCP(parseRequestCPPayload(requestCPPayload));
        break;
      case "requestBYOA":
        requestBYOA(requestBYOAPayload);
        break;
    }
    resetDialog();
  };

  useEffect(() => {
    const message =
      requestCPResp?.message ||
      requestCPResp?.detail?.message ||
      JSON.stringify(requestCPResp?.detail?.[0]);
    if (message?.length && typeof requestCPRespStatus === "number") {
      setMessage(message);
      setSeverity(requestCPRespStatus === 200 ? "success" : "error");
      setNotify(true);
    }
  }, [requestCPResp, requestCPRespStatus]);

  useEffect(() => {
    const message = hibResp?.message || hibResp?.detail?.message;
    if (message?.length && typeof hibRespStatus === "number") {
      setMessage(message);
      setSeverity(hibRespStatus === 200 ? "success" : "error");
      setNotify(true);
      if (hibRespStatus === 200) {
        getEnvs(showAllEnvs(toShowAllEnvs));
      }
    }
  }, [hibResp, hibRespStatus]);

  useEffect(() => {
    const message = wakeupResp?.message || wakeupResp?.detail?.message;
    if (message?.length && typeof wakeupRespStatus === "number") {
      setMessage(message);
      setSeverity(wakeupRespStatus === 200 ? "success" : "error");
      setNotify(true);
      if (wakeupRespStatus === 200) {
        getEnvs(showAllEnvs(toShowAllEnvs));
      }
    }
  }, [wakeupResp, wakeupRespStatus]);

  useEffect(() => {
    const message = refreshEnvResp?.message || refreshEnvResp?.detail?.message;
    if (message?.length && typeof refreshEnvRespStatus === "number") {
      setMessage(message);
      setSeverity(refreshEnvRespStatus === 200 ? "success" : "error");
      setNotify(true);
      if (refreshEnvRespStatus === 200) {
        getEnvs(showAllEnvs(toShowAllEnvs));
      }
    }
  }, [refreshEnvResp, refreshEnvRespStatus]);

  useEffect(() => {
    const message =
      convertToVPTCResp?.message || convertToVPTCResp?.detail?.message;
    if (message?.length && typeof convertToVPTCRespStatus === "number") {
      setMessage(message);
      setSeverity(convertToVPTCRespStatus === 200 ? "success" : "error");
      setNotify(true);
      if (convertToVPTCRespStatus === 200) {
        getEnvs(showAllEnvs(toShowAllEnvs));
      }
    }
  }, [convertToVPTCResp, convertToVPTCRespStatus]);

  useEffect(() => {
    const message =
      requestBYOAResp?.message || requestBYOAResp?.detail?.message;
    if (message?.length && typeof requestBYOARespStatus === "number") {
      setMessage(message);
      setSeverity(requestBYOARespStatus === 200 ? "success" : "error");
      setNotify(true);
    }
  }, [requestBYOAResp, requestBYOARespStatus]);

  useEffect(() => {
    const message =
      addLicencesResp?.message || addLicencesResp?.detail?.message;
    if (message?.length && typeof addLicencesRespStatus === "number") {
      setMessage(message);
      setSeverity(addLicencesRespStatus === 200 ? "success" : "error");
      setNotify(true);
      if (addLicencesRespStatus === 200) {
        getEnvs(showAllEnvs(toShowAllEnvs));
      }
    }
  }, [addLicencesResp, addLicencesRespStatus]);

  useEffect(() => {
    const message =
      deleteEnvResp?.message ||
      deleteEnvResp?.detail?.message ||
      deleteEnvResp?.detail;
    if (message?.length && typeof deleteEnvRespStatus === "number") {
      setMessage(message);
      setSeverity(deleteEnvRespStatus === 200 ? "success" : "error");
      setNotify(true);
      if (deleteEnvRespStatus === 200) {
        getEnvs(showAllEnvs(toShowAllEnvs));
      }
    }
  }, [deleteEnvResp, deleteEnvRespStatus]);

  const handleHibernateWakeUp = (env, action) => {
    if (action === "HIBERNATE") {
      const url = env?.url;
      setConfirmationDialog({
        isOpen: true,
        title: "Hibernate Environment",
        content: `You are going to hibernate the environment ${env?.name}. Are you sure?`,
        handleDialogConfirm: () => {
          hibernate({ url });
        },
      });
    } else {
      setDialog({
        selectedEnv: env,
        context: "wakeUp",
        title: "Wake Up environment",
        isOpen: true,
      });
    }
  };

  const handleRefresh = (env) => {
    setRefreshDialog({
      selectedEnv: env,
      isOpen: true,
    });
  };

  const handleConvertToVPTC = (env) => {
    const url = env?.url;
    setConfirmationDialog({
      isOpen: true,
      title: "Convert Environment to VPT@C",
      content: `You are going to convert the environment ${env?.name} to VPT@C`,
      handleDialogConfirm: () => {
        convertToVPTC({ url });
      },
    });
  };

  const handleRequestBYOA = (env) => {
    setDialog({
      selectedEnv: env,
      context: "requestBYOA",
      title: "Request BYOA Subscription",
      isOpen: true,
    });
  };

  const handleAddLicences = (env) => {
    const url = env?.url;
    setConfirmationDialog({
      isOpen: true,
      title: "Add Licences",
      content: `You are going to add Licences for the ${env?.name}`,
      handleDialogConfirm: () => {
        addLicences({ url });
      },
    });
  };

  const handleDelete = useCallback((env) => {
    const url = env?.url;
    setConfirmationDialog({
      isOpen: true,
      title: "Delete Environment",
      content: `You are going to delete the environment ${env?.name}. Are you sure?`,
      handleDialogConfirm: () => {
        deleteEnv({ url });
      },
    });
  });

  const handleRequestCP = () => {
    setDialog({
      selectedEnv: null,
      context: "requestCP",
      title: "Request Control Plane",
      isOpen: true,
    });
  };

  const showAllEnvs = (toShow) => (toShow ? "?show_all=true" : "");

  const createReqBodyForEnvId = (envId) => {
    const envWithUpdates = envsWithUpdates[idToIndexMap[envId]];
    const updatesInEnv = updates[envId];
    const reqBody = {};

    if (updatesInEnv?.hasUpdatesInMembers) {
      const members = envWithUpdates?.indexedMembers
        ?.filter((email) => email in envWithUpdates?.members)
        ?.map((email) => {
          return {
            email: email,
            role_name: roles[envWithUpdates?.members[email]],
          };
        });
      reqBody.members = members;
    }

    if (updatesInEnv?.hasConfigUpdates) {
      const config = { ...envWithUpdates?.config };
      delete config.dbServicesAutoDelete;

      config.hibernate.enableAutomaticHibernation =
        envWithUpdates?.autoHibernateEnabled;
      config.hibernate.schedule.utctime = convertLocalTimeToUTCTime(
        envWithUpdates?.autoHibernateSchedule
      );
      config.hibernate.schedule.repeat =
        envWithUpdates?.autoHibernateRepeat?.reduce(
          (out, bool, index) => (bool ? out.concat(index) : out),
          []
        );
      config.hibernate.schedule.notifyBeforeMinutes =
        envWithUpdates?.autoHibernateNotify.reduce(
          (out, bool, index) =>
            bool ? out.concat(notifyMinOptions[index]) : out,
          []
        );

      config.wakeup.enableAutomaticWakeup = envWithUpdates?.autoWakeupEnabled;
      config.wakeup.schedule.utctime = convertLocalTimeToUTCTime(
        envWithUpdates?.autoWakeupSchedule
      );
      config.wakeup.schedule.repeat = envWithUpdates?.autoWakeupRepeat?.reduce(
        (out, bool, index) => (bool ? out.concat(index) : out),
        []
      );
      config.wakeup.schedule.notifyBeforeMinutes =
        envWithUpdates?.autoWakeupNotify?.reduce(
          (out, bool, index) =>
            bool ? out?.concat(notifyMinOptions[index]) : out,
          []
        );
      reqBody.config = config;
    }
    return reqBody;
  };

  const saveUpdates = () => {
    setIsSavingUpdates(true);
    const envIdsWithUpdates = Object.keys(updates).filter(
      (envId) => updates[envId].hasUpdates
    );
    const succeededPatches = [];
    const failedPatches = [];
    const patchCalls = [];
    envIdsWithUpdates.forEach((envId) =>
      patchCalls.push(
        patchEnv(
          `${tenantInstancesUrl}/${envs[idToIndexMap[envId]].url}`,
          createReqBodyForEnvId(envId)
        )
      )
    );

    Promise.all(patchCalls).then((responses) => {
      responses.forEach((response, index) => {
        const envId = envIdsWithUpdates[index];
        const envName = envs[idToIndexMap[envId]].name;
        if (response.status === 200) {
          succeededPatches.push(envId);
        } else {
          failedPatches.push(envName);
        }
      });

      setUpdates((prev) => {
        const currUpdates = { ...prev };
        succeededPatches.forEach((envId) => delete currUpdates[envId]);
        return currUpdates;
      });

      if (failedPatches.length > 0) {
        setMessage(`Updates for envs failed: ${failedPatches.join(", ")}`);
        setSeverity("error");
      } else {
        setMessage("Successfully updated");
        setSeverity("success");
      }
      setNotify(true);
      getEnvs(showAllEnvs(toShowAllEnvs));
      setIsSavingUpdates(false);
    });
  };

  const discardUpdates = () => {
    setUpdates({});
    setUpdatesIsEmpty(checkIfUpdatesIsEmpty({}));
    setEnvsWithUpdates(mergeEnvsWithUpdates({ passedUpdates: {} }));
    getEnvs(showAllEnvs(toShowAllEnvs));
  };

  const onSetUpdates = () => {
    setUpdatesIsEmpty(checkIfUpdatesIsEmpty());
    setEnvsWithUpdates(mergeEnvsWithUpdates());
  };

  const addMember = (envId, email, role) => {
    setUpdates((prev) => {
      if (!prev[envId]?.membersAdded) {
        prev[envId] = {
          ...(prev[envId] && prev[envId]),
          membersAdded: new Map(),
          addedMembersIndex: [],
        };
      }
      if (prev[envId].membersDeleted) {
        prev[envId].membersDeleted.delete(email);
      }
      const env = envs[idToIndexMap[envId]];
      if (env?.indexedMembers?.includes(email)) {
        if (env?.members[email] !== role) {
          if (!prev[envId]?.membersModified) {
            prev[envId].membersModified = new Map();
          }
          prev[envId]?.membersModified?.set(email, role);
        }
      } else {
        prev[envId]?.membersAdded?.set(email, role);
        prev[envId]?.addedMembersIndex?.push(email);
      }
      return prev;
    });
    setUpdatesIsEmpty(checkIfUpdatesIsEmpty());
    setEnvsWithUpdates(mergeEnvsWithUpdates());
  };

  const deleteMember = (envId, email) => {
    setUpdates((prev) => {
      if (!prev[envId]?.membersDeleted) {
        prev[envId] = {
          ...(prev[envId] && prev[envId]),
          membersDeleted: new Set(),
        };
      }
      prev[envId].membersDeleted.add(email);
      if (prev[envId]?.membersAdded) {
        prev[envId]?.membersAdded?.delete(email);
        prev[envId].addedMembersIndex = prev[envId]?.addedMembersIndex?.filter(
          (item) => item !== email
        );
      }
      if (prev[envId]?.membersModified) {
        prev[envId]?.membersModified?.delete(email);
      }
      if (!(email in envs[idToIndexMap[envId]].members)) {
        prev[envId]?.membersDeleted?.delete(email);
      }
      return prev;
    });
    setUpdatesIsEmpty(checkIfUpdatesIsEmpty());
    setEnvsWithUpdates(mergeEnvsWithUpdates());
  };

  const modifyMemberRole = (envId, email, role) => {
    setUpdates((prev) => {
      if (!prev[envId]?.membersModified) {
        prev[envId] = {
          ...(prev[envId] && prev[envId]),
          membersModified: new Map(),
        };
      }
      if (prev[envId]?.membersAdded?.has(email)) {
        prev[envId]?.membersAdded?.set(email, role);
      } else {
        prev[envId]?.membersModified?.set(email, role);
      }
      return prev;
    });
    setUpdatesIsEmpty(checkIfUpdatesIsEmpty());
    setEnvsWithUpdates(mergeEnvsWithUpdates());
  };

  const getStatusColor = (status) => {
    if (status.includes("FAILED")) {
      return red[500];
    }
  };

  const columns = [
    {
      field: "name",
      flex: 3,
      cellClassName: "name-column--cell",
      renderHeader: () => <Typography variant="h5">Name</Typography>,
      renderCell: ({ row: { name, url } }) => {
        return url ? (
          <Link
            to={`https://${url}`}
            target="_blank"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Typography variant="h5">{name || ""}</Typography>
          </Link>
        ) : (
          <Typography variant="h5">{name || ""}</Typography>
        );
      },
    },
    {
      field: "status",
      renderHeader: () => <Typography variant="h5">Status</Typography>,
      flex: 2.5,
      renderCell: ({ row: { status } }) => (
        <Typography variant="h5" color={getStatusColor(status)}>
          {status || ""}
        </Typography>
      ),
    },
    {
      field: "action",
      renderHeader: () => (
        <Typography variant="h5">Hibernate / Wakeup</Typography>
      ),
      headerAlign: "center",
      flex: 3,
      renderCell: ({ row }) => {
        const disabled = !HIBERNATE_WAKEUP_ENABLED_STATUS.includes(row?.status);
        if (!row?.memberRole) return <></>;

        return row?.status === "HIBERNATE_FAILED" ||
          row?.status === "WAKEUP_FAILED" ? (
          <DropDownButton
            disabled={disabled}
            row={row}
            handleHibernateWakeUp={handleHibernateWakeUp}
          ></DropDownButton>
        ) : (
          <DataGridButton
            sx={dataGridButtonStyles.wakeUpButton}
            onClick={() =>
              handleHibernateWakeUp(
                row,
                row?.status === "HIBERNATED" ? "WAKE UP" : "HIBERNATE"
              )
            }
            disabled={disabled}
          >
            <Typography sx={dataGridButtonTypography(colors)}>
              {row?.status === "HIBERNATED" ? "WAKE UP" : "HIBERNATE"}
            </Typography>
          </DataGridButton>
        );
      },
    },
    {
      field: "refreshEnv",
      renderHeader: () => (
        <Typography variant="h5">Refresh environment</Typography>
      ),
      headerAlign: "center",
      flex: 3,
      renderCell: ({ row }) => {
        if (!row?.memberRole) return <></>;

        const disabled =
          row?.status !== "RUNNING" &&
          row?.status !== "UPDATE_FAILED" &&
          row?.status !== "WAKEUP_FAILED";
        return (
          <DataGridButton
            sx={dataGridButtonStyles.wakeUpButton}
            onClick={() => handleRefresh(row)}
            disabled={disabled}
          >
            <Typography sx={dataGridButtonTypography(colors)}>
              REFRESH
            </Typography>
          </DataGridButton>
        );
      },
    },
    {
      field: "createdBy",
      renderHeader: () => <Typography variant="h5">Created By</Typography>,
      flex: 3,
      renderCell: ({ row: { createdBy } }) => (
        <Typography variant="h5">{createdBy || ""}</Typography>
      ),
    },
    {
      field: "memberRole",
      renderHeader: () => <Typography variant="h5">User Role</Typography>,
      flex: 2,
      renderCell: ({ row: { memberRole } }) => (
        <Typography variant="h5">{memberRole || ""}</Typography>
      ),
    },
    {
      field: "age",
      renderHeader: () => <Typography variant="h5">Age</Typography>,
      flex: 1,
      renderCell: ({ row: { age, createdOn } }) => (
        <Tooltip
          title={<Typography>Created on {createdOn || NaN} </Typography>}
        >
          <Typography variant="h5">{age || (age === 0 ? 0 : NaN)} d</Typography>
        </Tooltip>
      ),
    },
    {
      field: "date_modified",
      renderHeader: () => (
        <Typography variant="h5">Last Modified At</Typography>
      ),
      flex: 3,
      renderCell: ({ row: { lastModifiedAt } }) => (
        <Typography variant="h5">{lastModifiedAt || ""}</Typography>
      ),
    },
    {
      field: "actions",
      type: "actions",
      flex: 1.5,
      getActions: ({ row }) =>
        !row?.memberRole
          ? []
          : [
              <GridActionsCellItem
                label="Copy Repo Config"
                icon={
                  <Tooltip title="Copy Repo Config">
                    <ContentCopy />
                  </Tooltip>
                }
                onClick={() => {
                  navigator.clipboard.writeText(row?.repoConfig);
                }}
              />,
              <GridActionsCellItem
                label="Automation Runs"
                onClick={() =>
                  navigate(`/tenants/${row?.url}/automation-runs`, {
                    state: { tenantUrl: row?.url },
                  })
                }
                showInMenu
              />,
              <GridActionsCellItem
                label="Delete"
                onClick={() => handleDelete(row)}
                showInMenu
              />,
              row?.meta?.service_plan === "BUSINESS" &&
                row?.status === "RUNNING" && (
                  <GridActionsCellItem
                    label="Convert to VPT@C"
                    onClick={() => handleConvertToVPTC(row)}
                    showInMenu
                  />
                ),
              false &&
                row?.meta?.service_plan ===
                  "VIRTUAL_PRIVATE_TESSELL_AT_CUSTOMER" && (
                  <GridActionsCellItem
                    label="Request BYOA Subscription"
                    onClick={() => handleRequestBYOA(row)}
                    showInMenu
                  />
                ),
              false &&
                row?.meta?.service_plan ===
                  "VIRTUAL_PRIVATE_TESSELL_AT_CUSTOMER" && (
                  <GridActionsCellItem
                    label="Mange BYOA Subscriptions"
                    onClick={() => {
                      setByoaModal({ selectedEnv: row, isOpen: true });
                    }}
                    showInMenu
                  />
                ),
              row?.meta?.service_plan ===
                "VIRTUAL_PRIVATE_TESSELL_AT_CUSTOMER" &&
                row?.status === "RUNNING" && (
                  <GridActionsCellItem
                    label="Add Licences"
                    onClick={() => handleAddLicences(row)}
                    showInMenu
                  />
                ),
            ].filter(Boolean),
    },
  ];

  const getDetailPanelContent = ({ row }) => {
    if (!row?.memberRole) return <></>;
    return (
      <Grid
        container
        sx={{ py: 2, height: "100%", boxSizing: "border-box" }}
        justifyContent={"space-around"}
      >
        <Grid xs={12} md={6} xl={4}>
          <Paper
            elevation={4}
            sx={{
              flex: 1,
              mx: "auto",
              width: "99%",
              p: 1,
              height: "100%",
              border: `1px solid ${colors.grey[400]}`,
            }}
          >
            <MembersList
              flex={2}
              env={row}
              addMember={addMember}
              deleteMember={deleteMember}
              modifyMemberRole={modifyMemberRole}
            />
          </Paper>
        </Grid>
        <Grid xs={12} md={6} xl={7}>
          <Paper
            elevation={4}
            sx={{
              flex: 1,
              mx: "auto",
              width: "99%",
              p: 1,
              height: "100%",
              border: `1px solid ${colors.grey[400]}`,
            }}
          >
            <ScheduledConfigs
              env={row}
              envOrig={envs[idToIndexMap[row?.id]]}
              setUpdates={setUpdates}
              onSetUpdates={onSetUpdates}
              flex={3}
            />
          </Paper>
        </Grid>
      </Grid>
    );
  };
  const getDetailPanelHeight = useCallback(() => "auto", []);

  return (
    <Box sx={indexStyles.mainBox}>
      <Notification
        notify={notify}
        severity={severity}
        setNotify={setNotify}
        message={message}
      />
      <ConvoyDialog
        open={dialog?.isOpen}
        title={dialog?.title}
        handleDialogConfirm={handleDialogConfirm}
        disabled={
          dialog?.context === "requestCP" &&
          !isValidRequestCPPayload(requestCPPayload)
        }
        resetDialog={resetDialog}
      >
        {(() => {
          switch (dialog.context) {
            case "wakeUp":
              return (
                <WakeupDialogContent
                  env={dialog?.selectedEnv}
                  setRefreshDialog={setRefreshDialog}
                  setDialog={setDialog}
                />
              );
            case "requestCP":
              return (
                <RequestCPDialogContent
                  requestCPPayload={requestCPPayload}
                  setRequestCPPayload={setRequestCPPayload}
                />
              );
            case "requestBYOA":
              return (
                <RequestBYOADialogContent
                  env={dialog?.selectedEnv}
                  requestBYOAPayload={requestBYOAPayload}
                  setRequestBYOAPayload={setRequestBYOAPayload}
                />
              );
            default:
              return <></>;
          }
        })()}
      </ConvoyDialog>
      <RefreshDialog
        refreshDialog={refreshDialog}
        setRefreshDialog={setRefreshDialog}
        refreshEnv={refreshEnv}
      />
      <ConfirmationDialog
        isOpen={confirmationDialog.isOpen}
        title={confirmationDialog.title}
        handleDialogConfirm={confirmationDialog.handleDialogConfirm}
        content={confirmationDialog.content}
        resetDialog={resetDialog}
      />
      <BYOASubscriptionsModal
        byoaModal={byoaModal}
        setByoaModal={setByoaModal}
      />
      <Grid sx={indexStyles.gridContainer} container columns={2}>
        <Grid xs={1}>
          <Header
            title="Environments"
            subtitle="Manage the environments you have access to"
          />
        </Grid>
        <Grid xs={1}>
          <Stack direction={"row-reverse"}>
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              onClick={handleRequestCP}
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                CREATE NEW
              </Typography>
            </DataGridButton>
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              disabled={updatesIsEmpty || isSavingUpdates}
              onClick={() => saveUpdates()}
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                SAVE
              </Typography>
            </DataGridButton>
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              hue={colors.red}
              disabled={updatesIsEmpty || isSavingUpdates}
              onClick={() => discardUpdates()}
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                RESET
              </Typography>
            </DataGridButton>
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              onClick={() => getEnvs(showAllEnvs(toShowAllEnvs))}
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                RELOAD
              </Typography>
            </DataGridButton>
            {false ? (
              <DataGridButton
                sx={dataGridButtonStyles.pageTopButtons}
                onClick={() => setByoaModal({ isOpen: true })}
              >
                <Typography sx={dataGridButtonTypography(colors, 500)}>
                  BYOA Subs
                </Typography>
              </DataGridButton>
            ) : (
              <></>
            )}
            <Box sx={indexStyles.showAllEnvsBox}>Show All Envs</Box>
            <Switch
              color="secondary"
              checked={toShowAllEnvs}
              onChange={() => setToShowAllEnvs(!toShowAllEnvs)}
            ></Switch>
          </Stack>
        </Grid>
      </Grid>

      <Box sx={indexStyles.dataGridBoxStyles(colors)}>
        <DataGridPro
          disableSelectionOnClick
          loading={isLoadingEnvs || isSavingUpdates}
          rows={envsWithUpdates}
          columns={columns}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
          slots={{
            footer: CustomEnvFooter,
          }}
          slotProps={{
            footer: { statusCounts },
          }}
        />
      </Box>
    </Box>
  );
};

export default Environments;
