import {
  Checkbox,
  CircularProgress,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../../../theme";
import ConvoyDialog from "../../../../../components/ConvoyDialog";
import MultiSelect from "../../../../../components/MultiSelect";
import { Fragment, useEffect, useState } from "react";
import { toTitleCase } from "../../../../../utils/helperFunctions";
import { useGetTenantServices } from "../../../../../api/envs";
import ArtifactTypeAccordion from "./ArtifactTypeAccordion";
import { adaptReleaseManifest } from "../../../../../adapters/refresh";
import { refreshDialogStyles } from "../../../styles";
import {
  getListOfServices,
  getReleaseManifestOverride,
} from "../helperFunctions";
import { stackCenterGap } from "../../../../../styles/utilStyles";

const CustomRefresh = ({
  env,
  selectedRefreshBase,
  setRefreshType,
  resetDialog,
  refreshEnv,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const {
    postData: getTenantServices,
    response: getTenantServicesResp,
    isLoading: isLoadingTenantServices,
  } = useGetTenantServices();

  const [updatedReleaseManifest, setUpdatedReleaseManifest] = useState({});
  const [originalReleaseManifest, setOriginalReleaseManifest] = useState({});
  const [additionalReleaseManifest, setAdditionalReleaseManifest] = useState(
    {}
  );
  const [toUpdateTenantComponents, setToUpdateTenantComponents] = useState({
    upgradeTfAndDpLibs: true,
    updateServices: true,
    updateUi: true,
    updateSeedData: true,
  });
  const [isDefaultBaseConfigBranch, setIsDefaultBaseConfigBranch] =
    useState(true);
  const [baseConfigBranch, setBaseConfigBranch] = useState("");
  const [isConfirmationDialog, setIsConfirmationDialog] = useState(false);
  const [availableServices, setAvailableServices] = useState([]);
  const [visibleServices, setVisibleServices] = useState([]);

  const handleDialogConfirm = () => {
    if (isConfirmationDialog) {
      refreshEnv({
        url: env?.url,
        releaseLabel: selectedRefreshBase,
        releaseManifestOverride: getReleaseManifestOverride(
          updatedReleaseManifest,
          originalReleaseManifest,
          additionalReleaseManifest
        ),
        ...toUpdateTenantComponents,
        ...(() =>
          isDefaultBaseConfigBranch
            ? {}
            : { baseConfigBranch: baseConfigBranch })(),
      });
      resetDialog();
    } else {
      setIsConfirmationDialog(true);
    }
  };

  const handleCancel = () => {
    isConfirmationDialog
      ? setIsConfirmationDialog(false)
      : setRefreshType("simple");
  };

  useEffect(() => {
    getTenantServices(`/${selectedRefreshBase}`);
  }, []);

  useEffect(() => {
    if (getTenantServicesResp && typeof getTenantServicesResp === "object") {
      const adaptedTenantServicesResp = adaptReleaseManifest(
        getTenantServicesResp
      );
      setUpdatedReleaseManifest(adaptedTenantServicesResp);
      setOriginalReleaseManifest(adaptedTenantServicesResp);
      setAdditionalReleaseManifest(
        Object.fromEntries(
          Object.keys(adaptedTenantServicesResp).map((item) => [item, []])
        )
      );
    }
  }, [getTenantServicesResp]);

  useEffect(() => {
    if (originalReleaseManifest) {
      const listOfservices = getListOfServices(originalReleaseManifest);
      setAvailableServices(listOfservices);
      setVisibleServices(listOfservices);
    }
  }, [originalReleaseManifest]);

  return (
    <ConvoyDialog
      open={true}
      title="Refresh environment"
      handleDialogConfirm={handleDialogConfirm}
      confirmBtnText={isConfirmationDialog ? "CONFIRM" : "NEXT"}
      resetDialog={resetDialog}
      onCancelClick={handleCancel}
      cancelBtnText="BACK"
    >
      <Stack direction="row" alignItems="center">
        {Object.keys(toUpdateTenantComponents).map((item, idx) => {
          return (
            <Fragment key={idx}>
              <Typography>{toTitleCase(item)}</Typography>
              <Checkbox
                color="secondary"
                checked={toUpdateTenantComponents[item]}
                onChange={() =>
                  setToUpdateTenantComponents((prev) => ({
                    ...prev,
                    [item]: !prev[item],
                  }))
                }
                sx={refreshDialogStyles.checkBoxStyle}
              />
            </Fragment>
          );
        })}
        <Typography>Default baseConfigBranch</Typography>
        <Checkbox
          color="secondary"
          checked={isDefaultBaseConfigBranch}
          onChange={() => setIsDefaultBaseConfigBranch((prev) => !prev)}
          sx={refreshDialogStyles.checkBoxStyle}
          variant="outlined"
          size="small"
        />
        <Stack sx={stackCenterGap} />
        {isDefaultBaseConfigBranch ? (
          <></>
        ) : (
          <TextField
            disabled={isDefaultBaseConfigBranch}
            value={baseConfigBranch}
            sx={refreshDialogStyles.baseConfigBranchtextFieldStyle}
            label="baseConfigBranch"
            onChange={(e) => setBaseConfigBranch(e.target.value)}
            size="small"
          />
        )}
      </Stack>
      <Stack direction="row" alignItems="center">
        <MultiSelect
          allItems={availableServices}
          setSelectedItems={setVisibleServices}
          label="Filter"
          limit={2}
          sx={refreshDialogStyles.searchBar}
        />
      </Stack>
      {isLoadingTenantServices ? (
        <Stack>
          <CircularProgress
            color="inherit"
            sx={refreshDialogStyles.circularProgressStyle}
          />
        </Stack>
      ) : (
        Object.keys(originalReleaseManifest).map(
          (artifactType, artifactTypeIdx) => {
            return (
              <ArtifactTypeAccordion
                key={artifactTypeIdx}
                isConfirmationDialog={isConfirmationDialog}
                artifactType={artifactType}
                originalReleaseManifest={originalReleaseManifest}
                updatedReleaseManifest={updatedReleaseManifest}
                setUpdatedReleaseManifest={setUpdatedReleaseManifest}
                additionalReleaseManifest={additionalReleaseManifest}
                setAdditionalReleaseManifest={setAdditionalReleaseManifest}
                visibleServices={visibleServices}
              />
            );
          }
        )
      )}
    </ConvoyDialog>
  );
};

export default CustomRefresh;
