import { useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Header from "../../components/Header";
import PackageVulnerabilities from "./PackageVulnerabilities";
import { tabbedDataGridPage } from "../../styles/commonPageStyles";

const Vulnerabilities = () => {
  const [selectedTab, setSelectedTab] = useState("Package Vulnerabilities");
  const tabs = [
    {
      value: "Package Vulnerabilities",
      component: (
        <PackageVulnerabilities
          vulnerabilityType="cves"
          header={
            <Header
              title="Package Vulnerabilites"
              subtitle="See the list vulnerabilties from trivy"
            />
          }
        />
      ),
    },
    {
      value: "DockerFile Vulnerabilities",
      component: (
        <PackageVulnerabilities
          vulnerabilityType="docker-best-practices"
          header={
            <Header
              title="DockerFile Vulnerabilites"
              subtitle="See the list vulnerabilties from dockle"
            />
          }
        />
      ),
    },
  ];
  return (
    <Box sx={tabbedDataGridPage.mainBox}>
      <Box sx={tabbedDataGridPage.tabContainer}>
        <TabContext value={selectedTab}>
          <Box sx={tabbedDataGridPage.tabListBox}>
            <TabList
              onChange={(e, newValue) => setSelectedTab(newValue)}
              textColor="secondary"
              indicatorColor="secondary"
              sx={tabbedDataGridPage.tabList}
            >
              {tabs.map((tab, i) => (
                <Tab
                  key={i}
                  label={tab.value}
                  value={tab.value}
                  icon={<></>}
                  iconPosition="end"
                />
              ))}
            </TabList>
          </Box>
          {tabs.map((tab, i) => (
            <TabPanel key={i} value={tab.value}>
              {tab.component}
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </Box>
  );
};

export default Vulnerabilities;
