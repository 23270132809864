import moment from "moment";
import { useState, useEffect } from "react";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import DataGridButton from "../../components/DataGridButton";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useGetDCPrs } from "../../api/pullRequests";
import RedirectLink from "../../components/RedirectLink";
import { timeFormat } from "../../utils/constants";
import { dataGridButtonTypography } from "../../styles/DataGridStyles";
import { dataGridButtonStyles } from "../../styles/buttonStyles";
import { tabbedDataGridPage } from "../../styles/commonPageStyles";
import utilColors from "../../styles/utilColors";

const PendingDoubleCommitMerges = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [prs, setPrs] = useState([]);

  const {
    postData: getPrs,
    response: getPrsResp,
    isLoading: isLoadingPrs,
  } = useGetDCPrs();

  useEffect(() => {
    getPrs();
  }, []);

  useEffect(() => {
    if (Array.isArray(getPrsResp?.response)) {
      setPrs(getPrsResp.response);
    }
  }, [getPrsResp]);

  const columns = [
    {
      field: "repository",
      renderHeader: () => <Typography variant="h5">Repository Name</Typography>,
      flex: 2.5,
      cellClassName: "name-column--cell",
      renderCell: ({ row: { repository } }) => (
        <Typography variant="h5">{repository || ""}</Typography>
      ),
    },
    {
      field: "title",
      flex: 4.5,
      renderHeader: () => <Typography variant="h5">PR Title</Typography>,
      renderCell: ({ row: { title, html_url } }) => (
        <RedirectLink href={html_url} color={utilColors.skyBlue}>
          <Typography variant="h5">{title ?? ""}</Typography>
        </RedirectLink>
      ),
    },
    {
      field: "base_branch",
      flex: 1,
      renderHeader: () => <Typography variant="h5">Base</Typography>,
      renderCell: ({ row: { base_branch } }) => (
        <Typography variant="h5">{base_branch ?? ""}</Typography>
      ),
    },
    {
      field: "author",
      flex: 1.5,
      renderHeader: () => <Typography variant="h5">Author</Typography>,
      renderCell: ({ row: { author } }) => (
        <Typography variant="h5">{author ?? ""}</Typography>
      ),
    },
    {
      field: "created_at",
      flex: 2,
      renderHeader: () => <Typography variant="h5">Created At</Typography>,
      renderCell: ({ row: { created_at } }) => (
        <Typography variant="h5">
          {moment(created_at).format(timeFormat)}
        </Typography>
      ),
    },
  ];

  return (
    <Box sx={tabbedDataGridPage.tabContentMainBox}>
      <Grid sx={tabbedDataGridPage.gridContainer} container columns={2}>
        <Grid xs={1}>
          <Header
            title="Pending Double Commit Merges"
            subtitle="See the list of double commits pending to be merged"
          />
        </Grid>
        <Grid xs={1}>
          <Box sx={tabbedDataGridPage.topButtonStack}>
            <DataGridButton
              sx={dataGridButtonStyles.pageTopButtons}
              onClick={() => getPrs()}
            >
              <Typography sx={dataGridButtonTypography(colors, 500)}>
                RELOAD
              </Typography>
            </DataGridButton>
          </Box>
        </Grid>
      </Grid>
      <Box sx={tabbedDataGridPage.dataGridBoxStyles(colors)}>
        <DataGridPro
          disableSelectionOnClick
          getRowId={(row) => row?.api_url}
          loading={isLoadingPrs}
          rows={prs}
          columns={columns}
        />
      </Box>
    </Box>
  );
};

export default PendingDoubleCommitMerges;
