export const commonButtonStyles = (colors, m, hue) => ({
  m: m || "0 auto",
  p: "5px",
  display: "flex",
  justifyContent: "center",
  justifyItems: "center",
  backgroundColor: (hue && hue[600]) || colors.green[600],
  borderColor: (hue && hue[600]) || colors.green[600],
  "&:hover": {
    backgroundColor: (hue && hue[700]) || colors.green[700],
    borderColor: (hue && hue[700]) || colors.green[700],
    cursor: "pointer",
  },
  "&:disabled": {
    backgroundColor: colors.grey[600],
    borderColor: colors.grey[600],

    "&:hover": {
      cursor: "auto",
    },
  },
});

export const dataGridButtonStyles = {
  pageTopButtons: {
    m: "5px 0 0 2px",
    width: "150px",
  },
  refreshButton: {
    width: "auto",
  },
  wakeUpButton: {
    width: "70%",
  },
  patchButton: {
    width: "20%",
  },
  requestApproveButton: {
    m: "5px 0 0 5px",
    width: "30%",
  },
};
