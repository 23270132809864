import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import PatchLabelDialog from "../PatchLabelDialog";
import UpdateReleaseStatusDialog from "../UpdateReleaseStatusDialog";
import { usePatchLabel, usePatchReleaseStatus } from "../../../api/releases";
import ReleasesCard from "./ReleasesCard";
import Notification from "../../../components/Notification";
import styles from "../styles";

const patchLabelDialogDefault = {
  isOpen: false,
  release: "",
};
const updateReleaseStatusDialogDefault = {
  isOpen: false,
  release: "",
  current_status: "",
  options: [],
};

const ReleaseSummaryView = ({
  selectedAppGroup,
  getReleases,
  getReleasesResp,
}) => {
  const [releases, setReleases] = useState([]);
  const [patchLabelDialog, setPatchLabelDialog] = useState(
    patchLabelDialogDefault
  );
  const [updateReleaseStatusDialog, setUpdateReleaseStatusDialog] = useState(
    updateReleaseStatusDialogDefault
  );
  const [notify, setNotify] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const {
    postData: patchLabel,
    response: patchLabelResp,
    responseStatus: patchLabelRespStatus,
  } = usePatchLabel(selectedAppGroup);

  const {
    postData: patchReleaseStatus,
    response: patchReleaseStatusResp,
    responseStatus: patchReleaseStatusRespStatus,
  } = usePatchReleaseStatus(selectedAppGroup);

  useEffect(() => {
    if (Array.isArray(getReleasesResp?.response)) {
      setReleases(getReleasesResp.response);
    }
  }, [getReleasesResp]);

  useEffect(() => {
    if (patchLabelResp) {
      getReleases();
    }
  }, [patchLabelResp]);

  const handlePatchLabel = (release) => {
    setPatchLabelDialog({
      isOpen: true,
      release: release,
    });
  };
  const handleUpdateStatus = (release, current_status, options) => {
    setUpdateReleaseStatusDialog({
      isOpen: true,
      release: release,
      current_status: current_status,
      options: options,
    });
  };
  const resetDialog = () => {
    setPatchLabelDialog(patchLabelDialogDefault);
    setUpdateReleaseStatusDialog(updateReleaseStatusDialogDefault);
  };

  useEffect(() => {
    const message =
      patchLabelResp?.message ||
      patchLabelResp?.detail?.message ||
      patchLabelResp?.detail;
    if (message?.length && typeof patchLabelRespStatus === "number") {
      setMessage(message);
      setSeverity(patchLabelRespStatus < 300 ? "success" : "error");
      setNotify(true);
    }
  }, [patchLabelResp, patchLabelRespStatus]);

  useEffect(() => {
    const message =
      patchReleaseStatusResp?.message ||
      patchReleaseStatusResp?.detail?.message ||
      patchReleaseStatusResp?.detail;
    if (message?.length && typeof patchReleaseStatusRespStatus === "number") {
      setMessage(message);
      setSeverity(patchReleaseStatusRespStatus < 300 ? "success" : "error");
      setNotify(true);
      if (patchReleaseStatusRespStatus < 300) {
        getReleases();
      }
    }
  }, [patchReleaseStatusResp, patchReleaseStatusRespStatus]);

  return (
    <>
      <Notification
        notify={notify}
        severity={severity}
        setNotify={setNotify}
        message={message}
      />
      <UpdateReleaseStatusDialog
        updateReleaseStatusDialog={updateReleaseStatusDialog}
        patchReleaseStatus={patchReleaseStatus}
        resetDialog={resetDialog}
      />
      <PatchLabelDialog
        selectedAppGroup={selectedAppGroup}
        patchLabelDialog={patchLabelDialog}
        patchLabel={patchLabel}
        resetDialog={resetDialog}
      />
      <Grid container spacing={2} sx={styles.summaryViewStyle}>
        {releases?.map((label, i) => {
          return (
            <Grid item xs={6} key={i}>
              <ReleasesCard
                label={label}
                handlePatchLabel={handlePatchLabel}
                handleUpdateStatus={handleUpdateStatus}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ReleaseSummaryView;
