/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";
import { useGetLoginUrl } from "../../api/auth";
import Cache from "../../utils/Cache";

const OktaLogin = () => {
  const [oauthState, setOAuthState] = useState("");
  const { postData: getLoginUrl, response: loginUrlResp } = useGetLoginUrl();

  useEffect(() => {
    Cache.clear();
    getLoginUrl(
      `?initialUrlPath=${window.location.pathname}&redirectUrlBase=${window.location.origin}`
    );
  }, []);

  useEffect(() => {
    if (loginUrlResp?.url && !oauthState) {
      const newOAuthState = uuid();
      setOAuthState(newOAuthState);

      Cache.set("OAuthState", newOAuthState);
      Cache.set("CallbackNonce", loginUrlResp.callbackNonce);
      Cache.set("CallbackNonceExpiry", loginUrlResp.callbackNonceExpiry);

      const urlToUse = loginUrlResp.url.replace(
        "state=state",
        `state=state-${newOAuthState}`
      );
      window.location.replace(urlToUse);
    }
  }, [loginUrlResp, oauthState]);
};

export default OktaLogin;
