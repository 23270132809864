import { Link } from "@mui/material";

const RedirectLink = ({ href, children, color }) => {
  return (
    <Link
      href={href}
      target="_blank"
      underline="none"
      sx={{ display: "inline", color: color || "inherit" }}
      variant="h5"
    >
      {children}
    </Link>
  );
};
export default RedirectLink;
