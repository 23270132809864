export const shadowBox = (colors) => {
  return {
    backgroundColor: colors.primary[600],
    borderRadius: "5px",
    margin: "10px",
    my: "15px",
    padding: "10px",
    boxShadow: "0px 0px 10px 3px " + colors.grey[900],
  };
};

export const borderBox = (colors) => {
  return {
    backgroundColor: colors.primary[600],
    borderRadius: "5px",
    margin: "5px",
    padding: "10px",
    border: 1,
    borderColor: colors.blue[500],
  };
};
export const simpleCardStyle = (colors, borderColor = "#220707") => {
  return {
    border: `1px solid ${borderColor}`,
    padding: "16px",
    backgroundColor: colors.primary[600],
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    height: "100%",
  };
};
