import moment from "moment";
import YAML from "yaml";
import { Grid, Paper, TextField, Typography, useTheme } from "@mui/material";
import { timeFormat } from "../../utils/constants";
import { tokens } from "../../theme";
import { detailPanelContentSyles as styles } from "./styles";

const DetailPanelContent = ({ row }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Grid container sx={styles.gridContainerSyles}>
      <Grid xs={6} lg={8}>
        <Paper elevation={4} sx={styles.paperStyles(colors)}>
          <Typography variant="h5">Metadata</Typography>
          <TextField
            InputProps={styles.metadataTextFieldInputProps}
            variant="standard"
            multiline
            rows={25}
            sx={styles.metadataTextFieldSx}
            value={YAML.stringify(row?.metaData, null, 4)}
          />
        </Paper>
      </Grid>
      <Grid xs={6} lg={4}>
        <Paper elevation={4} sx={styles.paperStyles(colors)}>
          {row?.requestedBy && (
            <>
              <Typography sx={styles.textBlockHeadingTypoStyle} variant="h4">
                Requested by:
              </Typography>
              <Typography variant="h5">
                {row?.requestedBy?.split("@")[0]}
              </Typography>
              <Typography variant="h5">
                {moment(row?.dateRequested)?.format(timeFormat)}
              </Typography>
            </>
          )}
          {row?.administeredBy && (
            <>
              <Typography sx={styles.textBlockHeadingTypoStyle} variant="h4">
                Administered by:
              </Typography>
              <Typography variant="h5">
                {row?.administeredBy?.split("@")[0]}
              </Typography>
              <Typography variant="h5">
                {moment(row?.dateAdministered)?.format(timeFormat)}
              </Typography>
            </>
          )}
          {(row?.metaData?.Severity || row?.metaData?.level) && (
            <>
              <Typography sx={styles.textBlockHeadingTypoStyle} variant="h4">
                Severity
              </Typography>
              <Typography variant="h5">
                {row?.metaData?.Severity || row?.metaData?.level}
              </Typography>
            </>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};
export default DetailPanelContent;
