import { useState, useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import MultiSelect from "../../../components/MultiSelect";
import LabelBox from "./LabelBox";
import { shadowBox } from "../../../styles/cardStyles";
import styles from "../styles";
import { adaptReleases } from "../../../adapters/getReleasesForDetailedView";
import { removeDuplicates } from "../../../utils/helperFunctions";

const DetailView = ({ getReleasesResp, view }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [labelTypes, setLabelTypes] = useState([]);
  const [visibleLabelTypes, setVisibleLabelTypes] = useState([]);
  const [visibleLables, setVisibleLables] = useState([]);
  const [visibleCommitGroups, setVisibleCommitGroups] = useState([]);
  const [multiSelectList, setMultiSelectList] = useState({});

  useEffect(() => {
    if (Array.isArray(getReleasesResp?.response)) {
      setLabelTypes(adaptReleases(getReleasesResp?.response));
    }
  }, [getReleasesResp]);

  useEffect(() => {
    const labelTypesList = [];
    const labelsList = [];
    const commitGroupsList = [];

    labelTypes?.forEach((labelType) => {
      labelTypesList.push(labelType?.name);
      labelType?.labels?.forEach((label) => {
        labelsList.push(label?.name);
        label?.commitGroups?.forEach((commitGroup) => {
          commitGroupsList.push(commitGroup?.name);
        });
      });
    });

    setMultiSelectList({
      labelTypesList: removeDuplicates(labelTypesList),
      labelsList: removeDuplicates(labelsList),
      commitGroupsList: removeDuplicates(commitGroupsList),
    });
    setVisibleLabelTypes(labelTypesList);
    setVisibleLables(labelsList);
    setVisibleCommitGroups(commitGroupsList);
  }, [labelTypes]);

  useEffect(() => {
    if (view !== "service") {
      const labelsList = labelTypes
        ?.filter((labelType) => visibleLabelTypes.includes(labelType?.name))
        ?.map((labelType) => labelType?.labels?.map((label) => label?.name))
        ?.flat();

      setMultiSelectList((prev) => ({
        ...prev,
        labelsList: removeDuplicates(labelsList),
      }));
    }
  }, [visibleLabelTypes]);

  useEffect(() => {
    if (view === "service") {
      const commitGroupsList = labelTypes
        ?.map((labelType) => {
          return labelType?.labels
            ?.filter((label) => visibleLables.includes(label?.name))
            ?.map((label) =>
              label?.commitGroups?.map((commitGroup) => commitGroup?.name)
            );
        })
        .flat(2);
      setMultiSelectList((prev) => ({
        ...prev,
        commitGroupsList: removeDuplicates(commitGroupsList).sort(),
      }));
    }
  }, [visibleLables]);

  const multiSelectProps = (view) => {
    return view === "release"
      ? {
          releases: {
            allItems: multiSelectList?.labelTypesList,
            setSelectedItems: setVisibleLabelTypes,
          },
          patches: {
            allItems: multiSelectList?.labelsList,
            setSelectedItems: setVisibleLables,
          },
          services: {
            allItems: multiSelectList?.commitGroupsList,
            setSelectedItems: setVisibleCommitGroups,
          },
        }
      : {
          releases: {
            allItems: multiSelectList?.labelsList,
            setSelectedItems: setVisibleLables,
          },
          patches: {
            allItems: multiSelectList?.commitGroupsList,
            setSelectedItems: setVisibleCommitGroups,
          },
          services: {
            allItems: multiSelectList?.labelTypesList,
            setSelectedItems: setVisibleLabelTypes,
          },
        };
  };

  const labelTypeBoxes = labelTypes?.map((labelType, i) => {
    const labelBoxes = labelType?.labels
      ?.map((label, i) => {
        if (visibleLables?.includes(label?.name)) {
          return (
            <LabelBox
              label={label}
              visibleCommitGroups={visibleCommitGroups}
              key={i}
              view={view}
            />
          );
        }
      })
      .filter(Boolean);
    return (
      visibleLabelTypes.includes(labelType.name) && (
        <Box sx={shadowBox(colors)} key={i}>
          <Typography variant="h4" sx={styles.labelTypeHeading}>
            {labelType?.name}
          </Typography>
          {labelBoxes?.length ? (
            labelBoxes
          ) : (
            <Typography variant="h5" sx={styles.noData}>
              No Data
            </Typography>
          )}
        </Box>
      )
    );
  });

  return (
    <Box sx={styles.labelTypeViewStyle}>
      <Box sx={styles.multiSelectStack}>
        <MultiSelect
          {...multiSelectProps(view).releases}
          label="Releases"
          limit={2}
          sx={styles.multiSelect}
        />
        <MultiSelect
          {...multiSelectProps(view).patches}
          label="Patches"
          limit={2}
          sx={styles.multiSelect}
        />
        <MultiSelect
          {...multiSelectProps(view).services}
          label="Services"
          limit={4}
          sx={styles.multiSelect}
        />
      </Box>
      {labelTypeBoxes}
    </Box>
  );
};

export default DetailView;
